import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { DropdownItem, Dropdown, Popover, Button, Icon } from '@just-ai/just-ui';
import isAccess, { isEuroInstance, isInternal, isSystemAccess } from '../../isAccessFunction';

import { t } from '../../localization';
import { PersonalInfo } from '../Sidebar/Unprojected';
import { cloudDomainsService } from 'services/CloudDomainsService';
import history from '../../appHistory';

import './User.scss';

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  logoutFromAll = () => {
    history.push('/logout');
  };

  render() {
    const { currentUser, loggedInAccount, tariffData, countryIsoCode } = this.props;

    return !isInternal() && currentUser ? (
      <div className='header__user'>
        <Button onClick={this.toggle} color='none' id='userMenuPopover' className='navbar-btn user-menu-btn'>
          {Boolean(currentUser.fullName) ? currentUser.fullName : currentUser.email || currentUser.login}
        </Button>
        <Popover
          isOpen={this.state.dropdownOpen}
          placement='bottom'
          target='userMenuPopover'
          className='header-menu-popover header-menu-popover-user User_container'
          toggle={this.toggle}
        >
          <Dropdown isOpen={true} toggle={this.toggle}>
            <div className='header-menu'>
              {(isAccess(['SPECIFIC_ACCOUNT_MANAGEMENT']) || isAccess(['ACCOUNTS_TARIFF_READ'])) &&
                isSystemAccess(['botadmin_billing_feature']) && (
                  <>
                    <Link key='link' to='/accountmanager' data-test-id='userHeader.userProfileLink'>
                      <PersonalInfo user={currentUser} tariffData={tariffData} />
                    </Link>
                    <DropdownItem divider />
                  </>
                )}
              <a
                href={`${cloudDomainsService.getFullDomainPath('cc')}/c/profile`}
                data-test-id='userHeader.userProfileLink'
              >
                <DropdownItem
                  data-test-id='userHeader.userProfile'
                  onClick={this.handleProfileNavigate}
                  className='header-menu__item'
                >
                  {t('Profile')}
                </DropdownItem>
              </a>
              {/* eslint-disable-next-line no-undef */}
              {isSystemAccess(['botadmin_partner_feature']) && countryIsoCode === 'RU' && (
                <a
                  href={t(isEuroInstance() ? 'partnership link Tovie AI' : 'partnership link')}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <DropdownItem data-test-id='userHeader.becomePartner' className='header-menu__item'>
                    {t('become partner')}
                    &nbsp;
                    <Icon color='secondary' size='xs' name='farExternalLink' />
                  </DropdownItem>
                </a>
              )}
              <DropdownItem divider />
              <DropdownItem
                data-test-id='userHeader.logoutLink'
                className='header-menu__item header-menu__item_logout'
                onClick={this.logoutFromAll}
              >
                {t('Header: logout')}
              </DropdownItem>
            </div>
          </Dropdown>
        </Popover>
      </div>
    ) : (
      Boolean(loggedInAccount) && (
        <div className='header__user header__user-internal'>
          <Button onClick={this.handleProfileNavigate} data-test-id='userHeader.userProfileLink'>
            {loggedInAccount.owner?.login || loggedInAccount.name}
          </Button>
          <Link data-test-id='userHeader.backToAccountsLink' to='#' onClick={this.props.logoutFromAcc}>
            {t('Return to accounts')}
          </Link>
        </div>
      )
    );
  }
}

export default User;
