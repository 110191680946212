import React, { createContext, useContext } from 'react';
import { WSContext, WSContextType } from './WSContext';
import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';
import {
  TaskStatusData,
  getLogOutputFromMessage,
  findFileUrl,
  download,
  getEarliestTimestamp,
  getNlpErrorFromOutput,
} from '../utils/tasks';
import localize from '../../../localization';
import { NotificationData } from '../api/client';
import { LogService } from 'modules/Logs/components/LogContext/LogService';
import { hasFeatures } from 'isAccessFunction';

export const WS_TASKS_PATH = '/user/topic/tasks';
export const TASKS_PATH = '/app/tasks';
export const TASKS_LIMIT = 15;
export const DELETE_PATH = '/app/tasks/delete';
export const LOCALSTORAGE_PREFIX = 'taskslist_hide-';
const HELP_MODAL_TIMEOUT = 5000;
const BOT_DEPLOY_POPOVER = 5000;
const BOT_DEPLOY_LONG = 3000;

const REPORTER_POPOVER = 3000;
const REPORTER_POPOVER_LONG = 3000;

const IGNORED_TASKS_UPDATE = [
  'text_campaign_service.reports.text_campaign_created',
  'text_campaign_service.reports.text_campaign_ready',
  'text_campaign_service.reports.text_campaign_progress',
  'text_campaign_service.reports.text_campaign_stopped',
  'text_campaign_service.reports.text_campaign_started',
  'reporter.reports.report_generation_task_deleted',
  'botadmin.logs.logs_download_task_deleted',
];

export type TasksContextType = {
  tasks: TaskStatusData[];
  deleteTask: (task: TaskStatusData) => void;
  deleteReportGenerationTask: (task: TaskStatusData) => void;
  deleteAllTasks: () => void;
  markHovered: (index: number) => void;
  markHoveredAll: () => void;
  isOpenModal: boolean;
  setModalOpen: (isOpen: boolean) => unknown;
  isOpenLog: boolean;
  setLogOpen: (isOpen: boolean) => unknown;
  selectedTaskId?: string;
  modalType?: string;
  popoverText: string;
  isOpenedDeployPopover: boolean;
  closeDeployPopover: () => unknown;
};

export const TasksContext = createContext({} as TasksContextType);

interface TasksProviderProps {
  projectShortName: string;
  deleteReportGenerationTask: (options: { reportGenerationTaskId: string; options?: any }) => void;
  deleteCompletedReportGenerationTasks: (options?: any) => void;
  addMessage: (message: AlertNotificationItemProps) => void;
}
interface TasksProviderState {
  tasks: TaskStatusData[];
  modalType?: string;
  isOpenModal: boolean;
  isOpenLog: boolean;
  selectedTaskId: string;
  popoverText: string;
  isOpenedDeployPopover: boolean;
  taskStartTimeStamp?: number;
  isAlreadyDelayed: boolean;
}

export class TasksProvider extends React.Component<TasksProviderProps, TasksProviderState> {
  static contextType = WSContext;
  context!: WSContextType;

  logService?: LogService;

  subscribeId = '';
  modalTimeoutId: NodeJS.Timeout | null = null;
  openDeployTimeoutId: NodeJS.Timeout | null = null;
  closeDeployTimeoutId: NodeJS.Timeout | null = null;

  openReporterTimeoutId: NodeJS.Timeout | null = null;
  closeReporterTimeoutId: NodeJS.Timeout | null = null;

  openLogsDownloadTimeoutId: NodeJS.Timeout | null = null;
  closeLogsDownloadTimeoutId: NodeJS.Timeout | null = null;

  state: TasksProviderState = {
    tasks: [],
    modalType: undefined,
    isOpenModal: false,
    isOpenLog: false,
    selectedTaskId: '',
    popoverText: '',
    isOpenedDeployPopover: false,
    taskStartTimeStamp: Infinity,
    isAlreadyDelayed: false,
  };

  componentDidMount() {
    this.subscribeId = this.context.subscribe(WS_TASKS_PATH, this.subscriber);
    this.context.send(TASKS_PATH, { size: TASKS_LIMIT });
    this.logService = new LogService(this.props.projectShortName);
  }

  componentWillUnmount() {
    if (this.subscribeId) this.context.unsubscribe(WS_TASKS_PATH, this.subscribeId);
  }

  setModalOpen = (isOpen: boolean) => this.setState({ isOpenModal: isOpen });
  setLogOpen = (isOpen: boolean) => this.setState({ isOpenLog: isOpen });
  openDeployPopover = (text: string) =>
    this.setState({
      popoverText: text,
      isOpenedDeployPopover: true,
    });
  closeDeployPopover = () => {
    if (this.openDeployTimeoutId) clearTimeout(this.openDeployTimeoutId);
    if (this.closeDeployTimeoutId) clearTimeout(this.closeDeployTimeoutId);
    if (this.openReporterTimeoutId) clearTimeout(this.openReporterTimeoutId);
    if (this.closeReporterTimeoutId) clearTimeout(this.closeReporterTimeoutId);
    if (this.openLogsDownloadTimeoutId) clearTimeout(this.openLogsDownloadTimeoutId);
    if (this.closeLogsDownloadTimeoutId) clearTimeout(this.closeLogsDownloadTimeoutId);
    this.setState({
      popoverText: '',
      isOpenedDeployPopover: false,
    });
  };

  openModal = (task: TaskStatusData) => {
    if (this.state.isAlreadyDelayed) return;
    if (this.modalTimeoutId) clearTimeout(this.modalTimeoutId);
    if (this.state.isOpenModal) return;

    const needTimeoutValue = task.message?.code?.code?.endsWith('task_created') ? 0 : HELP_MODAL_TIMEOUT;

    if (task.message?.code?.code?.endsWith('task_progress')) {
      return;
    }

    this.setState(
      {
        isAlreadyDelayed: true,
      },
      () => {
        this.modalTimeoutId = setTimeout(() => {
          if (
            !window.location.pathname.includes('/editor') &&
            this.state.tasks.some((item: TaskStatusData) => item.taskId === task.taskId && !item.completed)
          ) {
            this.setState({
              modalType: task?.message?.code?.code,
              isOpenModal: true,
              isAlreadyDelayed: false,
            });
          }
        }, needTimeoutValue);
      }
    );
  };

  openLog = (taskId: string) => {
    return this.setState(
      {
        selectedTaskId: taskId,
      },
      () => this.setLogOpen(true)
    );
  };

  getTaskIndex = (taskId: string) => {
    return this.state.tasks.findIndex((task: TaskStatusData) => task.taskId === taskId);
  };

  isEditorPage = () => window.location.pathname.includes('/editor');
  isDeploySkipped = (task: TaskStatusData) =>
    String(task.message?.data?.output) === 'Deploy skipped. Using last deploy result.';

  addAlertsFromDeployTask = (task: TaskStatusData) => {
    const code = task.message?.code?.code;
    const botId = task.message?.data?.botId;
    if (!code) return;

    if (botId) {
      window.dispatchEvent(new CustomEvent(`CHANNELS_${botId}_UPDATE`, { detail: task }));

      if (this.closeDeployTimeoutId) clearInterval(this.closeDeployTimeoutId);
      if (code === 'editorbe.deploy.bot') {
        this.openDeployTimeoutId = setTimeout(
          () => this.openDeployPopover(localize.translate('tasks publication start popover')),
          BOT_DEPLOY_POPOVER
        );
        this.closeDeployTimeoutId = setTimeout(this.closeDeployPopover, BOT_DEPLOY_POPOVER + BOT_DEPLOY_LONG);
        return;
      }

      if (this.openDeployTimeoutId) clearInterval(this.openDeployTimeoutId);
      this.closeDeployPopover();

      if (code.includes('editorbe.deploy.bot.finished')) {
        this.props.addMessage({
          type: 'info',
          title: localize.translate(`notifications ${code}`),
          message: getLogOutputFromMessage(task.message),
          time: Date.now(),
          showed: true,
          buttons: [
            {
              buttonText: localize.translate('notifications view log'),
              buttonAction: () => this.openLog(task.taskId),
            },
          ],
        });
      }
    }

    if (code.endsWith('task_completed')) {
      let messageWithFileUrl: TaskStatusData['message'] | string =
        task.message?.data?.fileUrl || findFileUrl(task.notifications);
      if (typeof messageWithFileUrl === 'object') {
        messageWithFileUrl = messageWithFileUrl.data?.fileUrl;
      }
      this.closeDeployPopover();

      if (messageWithFileUrl) {
        this.props.addMessage({
          type: 'info',
          title: localize.translate(`notifications ${code}`),
          message: localize.translate(`notifications body ${code}`),
          time: Date.now(),
          showed: true,
          buttons: [
            {
              buttonText: localize.translate('Download'),
              //@ts-ignore
              buttonAction: download(messageWithFileUrl, messageWithFileUrl),
            },
          ],
        });
      }
    }

    if (code.endsWith('report_generation_task_failed')) {
      if (this.closeReporterTimeoutId) clearTimeout(this.closeReporterTimeoutId);
      if (this.openReporterTimeoutId) clearTimeout(this.openReporterTimeoutId);
      this.closeDeployPopover();

      this.props.addMessage({
        type: 'error',
        title: localize.translate(`notifications ${task.message?.code?.text} ${task.message?.data?.reportType}`),
        message: localize.translate(
          `notifications body ${task.message?.code?.text}`,
          task.message?.data?.maxSizeOfRows,
          task.taskId
        ),
        time: Date.now(),
        showed: true,
        buttons: [],
      });
    }

    if (code.endsWith('report_generation_task_created') && (task.notifications || []).length <= 2) {
      if (this.closeReporterTimeoutId) clearTimeout(this.closeReporterTimeoutId);
      this.openReporterTimeoutId = setTimeout(
        () => this.openDeployPopover(localize.translate('reporter forming start popover')),
        REPORTER_POPOVER
      );
      this.closeReporterTimeoutId = setTimeout(this.closeDeployPopover, REPORTER_POPOVER + REPORTER_POPOVER_LONG);
    }

    if (code.endsWith('report_generation_task_completed')) {
      if (this.openReporterTimeoutId) clearTimeout(this.openReporterTimeoutId);
      if (this.closeReporterTimeoutId) clearTimeout(this.closeReporterTimeoutId);
    }

    if (code.endsWith('logs_download_task_failed')) {
      if (this.closeLogsDownloadTimeoutId) clearTimeout(this.closeLogsDownloadTimeoutId);
      if (this.openLogsDownloadTimeoutId) clearTimeout(this.openLogsDownloadTimeoutId);
      this.closeDeployPopover();

      this.props.addMessage({
        type: 'error',
        title: localize.translate(`notifications ${task.message?.code?.text} ${task.message?.data?.reportType}`),
        message: localize.translate(
          `notifications body ${task.message?.code?.text}`,
          task.message?.data?.maxSizeOfRows,
          task.taskId
        ),
        time: Date.now(),
        showed: true,
        buttons: [],
      });
    }

    if (code.endsWith('logs_download_task_created') && (task.notifications || []).length <= 2) {
      if (this.closeLogsDownloadTimeoutId) clearTimeout(this.closeLogsDownloadTimeoutId);
      this.openLogsDownloadTimeoutId = setTimeout(
        () => this.openDeployPopover(localize.translate('reporter forming start popover')),
        REPORTER_POPOVER
      );
      this.closeLogsDownloadTimeoutId = setTimeout(this.closeDeployPopover, REPORTER_POPOVER + REPORTER_POPOVER_LONG);
    }

    if (code.endsWith('logs_download_task_completed')) {
      if (this.closeLogsDownloadTimeoutId) clearTimeout(this.closeLogsDownloadTimeoutId);
      if (this.openLogsDownloadTimeoutId) clearTimeout(this.openLogsDownloadTimeoutId);
    }
  };

  addResultNotification = (task: TaskStatusData) => {
    if (
      task.notifications &&
      task.notifications.find(
        notification =>
          notification.message?.code?.code === task.message?.code?.code &&
          notification.message?.data?.output === task.message?.data?.output
      )
    )
      return;
    task.notifications = [
      {
        projectId: task.projectId,
        taskId: task.taskId,
        timestamp: task.timestamp,
        message: task.message,
        accountId: task.accountId,
      },
      ...task.notifications!,
    ];
  };

  updateTask = (task: TaskStatusData) => {
    if (task.projectId !== this.props.projectShortName) return;

    const code = task.message?.code?.code;

    if (code && IGNORED_TASKS_UPDATE.includes(code)) return;

    if (
      code?.includes('editorbe.deploy.bot') ||
      code?.endsWith('task_completed') ||
      code?.endsWith('task_failed') ||
      code?.endsWith('task_created')
    ) {
      this.addAlertsFromDeployTask(task);
    }

    const newTasks = [...this.state.tasks];

    const taskIndex = this.getTaskIndex(task.taskId);
    if (taskIndex === -1) {
      newTasks.unshift({
        ...task,
        hovered: true,
        startTimestamp: getEarliestTimestamp(task),
      });
      this.deduplicateNotifications(newTasks[0]);
    } else {
      newTasks[taskIndex] = {
        ...task,
        hovered: newTasks[taskIndex].hovered,
        startTimestamp: Math.min(getEarliestTimestamp(task), newTasks[taskIndex].startTimestamp || Infinity),
        notifications: [...(task.notifications || []), ...(newTasks[taskIndex].notifications || [])],
      };
      this.addResultNotification(newTasks[taskIndex]);
      this.deduplicateNotifications(newTasks[taskIndex]);
    }

    if (
      task.completed === false &&
      task.message?.code?.code &&
      localStorage.getItem(`${LOCALSTORAGE_PREFIX}${task.message.code.code}`) !== 'true' &&
      task.message?.code?.code !== 'editorbe.deploy.bot.info' &&
      !task.message.code.code.endsWith('task_progress') &&
      !task.message.code.code.endsWith('task_started') &&
      !task.message.code.code.endsWith('task_deleted') &&
      !task.message.code.code.endsWith('task_failed') &&
      !task.message.code.code.endsWith('task_stopped')
    ) {
      this.openModal(task);
    }

    this.setState({ tasks: newTasks });
  };

  checkTaskNotifications = (taskToUpdate: TaskStatusData, newTask: TaskStatusData) => {
    if (!taskToUpdate) return false;
    switch (newTask.message?.code?.code) {
      case 'editorbe.deploy.bot.info':
        if (!taskToUpdate.notifications) {
          return true;
        }
        return !taskToUpdate.notifications?.find(
          notification => notification.message?.data?.output === newTask.message?.data?.output
        );
      case 'reporter.reports.report_generation_task_deleted':
      case 'reporter.reports.report_generation_task_progress':
      case 'reporter.reports.report_generation_task_failed':
      case 'botadmin.logs.logs_download_task_deleted':
      case 'botadmin.logs.logs_download_task_progress':
      case 'botadmin.logs.logs_download_task_failed':
        if (taskToUpdate.notifications) {
          return true;
        }
      //fallthrough
      default:
        return false;
    }
  };

  subscriber = (body: any) => {
    if (Array.isArray(body)) {
      body.forEach(this.updateTask);
    } else if (Array.isArray(body.records)) {
      this.addEarlierTasks(body.records);
    }
  };

  addEarlierTasks = (tasks: TaskStatusData[]) => {
    tasks = tasks.filter(task => task.projectId === this.props.projectShortName);
    tasks.forEach(this.deduplicateNotifications);
    tasks.forEach(this.setStartTimestamp);
    tasks.forEach(this.extractNLUErrorFromTask);
    this.setState({ tasks });
  };

  setStartTimestamp = (task: TaskStatusData) => {
    task.startTimestamp = getEarliestTimestamp(task);
  };

  extractNLUErrorFromTask = (task: TaskStatusData) => {
    try {
      if (typeof task.message?.data?.output === 'string' && (task.message?.data?.output as string).includes('caila.')) {
        task.message.data.output = getNlpErrorFromOutput(task.message.data.output);
      }
    } catch (error) {}
  };

  deduplicateNotifications = (task: TaskStatusData) => {
    if (!task || !task.notifications) return;
    const uniqueNotifications: NotificationData[] = [];

    task.notifications.forEach(notification => {
      if (
        !uniqueNotifications.some(
          uniqueNotification =>
            this.hasSameCode(uniqueNotification, notification) && this.hasSameOutput(uniqueNotification, notification)
        )
      )
        uniqueNotifications.push(notification);
    });

    task.notifications = uniqueNotifications;
  };

  hasSameCode = (a: NotificationData, b: NotificationData) =>
    a.message?.code?.code && b.message?.code?.code && a.message.code.code === b.message.code.code;

  hasSameOutput = (a: NotificationData, b: NotificationData) =>
    a.message?.data?.output && b.message?.data?.output && a.message.data.output === b.message.data.output;

  deleteTask = (task: TaskStatusData) => {
    setTimeout(() => {
      if (typeof task.index === 'undefined') return;
      this.context.send(`${DELETE_PATH}/${task.taskId}`);
      const newTasks = [...this.state.tasks];
      const deletedTask = newTasks.splice(task.index, 1);
      if (deletedTask[0].message?.code?.code?.includes('reporter')) {
        this.props.deleteReportGenerationTask({
          reportGenerationTaskId: deletedTask[0].taskId,
        });
      }
      if (deletedTask[0].message?.code?.code?.includes('logs_download') && hasFeatures('export_system_log')) {
        this.logService?.deleteLogsDownloadTask(deletedTask[0].taskId);
      }
      this.setState({ tasks: newTasks });
    }, 0);
  };

  deleteReportGenerationTask = (task: TaskStatusData) => {
    setTimeout(() => {
      if (typeof task.index === 'undefined') return;
      const newTasks = [...this.state.tasks];
      const deletedTask = newTasks.splice(task.index, 1);
      this.props.deleteReportGenerationTask({
        reportGenerationTaskId: deletedTask[0].taskId,
      });
      this.setState({ tasks: newTasks });
    }, 0);
  };

  deleteAllTasks = () => {
    this.setState(prevState => ({ tasks: prevState.tasks.filter(task => !task.completed) }));
    this.context.send(`${DELETE_PATH}`);
    this.props.deleteCompletedReportGenerationTasks();
    if (hasFeatures('export_system_log')) this.logService?.deleteCompletedLogsDownloadTasks();
  };

  markHovered = (index: number) => {
    this.setState(prevState => {
      const prevTask = prevState.tasks[index];
      if (prevTask && prevTask.hovered) {
        const newTasks = [...prevState.tasks];
        newTasks[index] = { ...prevTask, hovered: false };
        return { tasks: newTasks };
      }
      return { tasks: prevState.tasks };
    });
  };

  markHoveredAll = () => {
    let isChanged = false;
    const newTasks = this.state.tasks.map((item: TaskStatusData) => {
      if (item.hovered && item.projectId === this.props.projectShortName) {
        item.hovered = false;
        isChanged = true;
      }
      return item;
    });
    isChanged && this.setState({ tasks: newTasks });
  };

  render() {
    return (
      <TasksContext.Provider
        value={{
          tasks: this.state.tasks,
          deleteReportGenerationTask: this.deleteReportGenerationTask,
          deleteTask: this.deleteTask,
          deleteAllTasks: this.deleteAllTasks,
          markHovered: this.markHovered,
          markHoveredAll: this.markHoveredAll,
          modalType: this.state.modalType,
          isOpenModal: this.state.isOpenModal,
          setModalOpen: this.setModalOpen,
          isOpenLog: this.state.isOpenLog,
          setLogOpen: this.setLogOpen,
          selectedTaskId: this.state.selectedTaskId,
          popoverText: this.state.popoverText,
          isOpenedDeployPopover: this.state.isOpenedDeployPopover,
          closeDeployPopover: this.closeDeployPopover,
        }}
      >
        {this.props.children}
      </TasksContext.Provider>
    );
  }
}

export const useTasks = () => useContext(TasksContext);
