export const defaultHelpUrlsPathLocalization = {
  'available channels link': {
    eng: '/channels',
    ru: '/channels',
  },
  'Entities docs link': {
    eng: '/platform_ux/nlu_core/entities',
    ru: '/platform_ux/nlu_core/entities',
  },
  'Spelling correction link': {
    eng: '/platform_ux/nlu_core/entities#spelling-correction',
    ru: '/platform_ux/nlu_core/spellcheck',
  },
  'NLUSettings help link': {
    eng: '/NLU_core/advanced_classifer_settings',
    ru: '/NLU_core/advanced_classifer_settings',
  },
  'tooltip calls link': {
    eng: '/telephony',
    ru: '/telephony',
  },
  'tooltip callsApi link': {
    eng: '/telephony/calls_api',
    ru: '/telephony/calls_api',
  },
  'tooltip nluApi link': {
    eng: '/NLU_core/NLP_direct_API',
    ru: '/NLU_core/NLP_direct_API',
  },
  'Header:GithubTokenBanner.linkHref': {
    eng: '/security/github_access_token',
    ru: '/security/github_access_token',
  },
  'ProjectOverview: JAICP link': {
    eng: '/platform_ux/results',
    ru: '/platform_ux/results',
  },
  'Widget:spellingPopoverLink': {
    eng: '/platform_ux/nlu_core/entities#spelling-correction',
    ru: '/platform_ux/nlu_core/spellcheck',
  },
  'ProjectOverview: noInfo client helpLink': {
    eng: '/platform_ux/volumes',
    ru: '/platform_ux/volumes',
    cn: '/platform_ux/volumes',
  },
  'ProjectOverview: noInfo helpLink clients': {
    eng: '/platform_ux/volumes',
    ru: '/platform_ux/volumes',
    cn: '/platform_ux/volumes',
  },
  'ProjectOverview: noInfo helpLink results': {
    eng: '/platform_ux/results',
    ru: '/platform_ux/results',
    cn: '/platform_ux/results',
  },
  'ProjectOverview: tooltip results button link': {
    eng: '/JS_API/built_in_services/analytics/setSessionResult',
    ru: '/JS_API/built_in_services/analytics/setSessionResult',
    cn: '/JS_API/built_in_services/analytics/setSessionResult',
  },
  'Analytic:Experiments modal main label documentation link': {
    eng: '/platform_ux/experiments',
    ru: '/platform_ux/experiments',
    cn: '/platform_ux/experiments',
  },
  'Logs:LogsMasking.EditRule.ruleHelpLinkHref': {
    eng: '/security/log_obfuscation',
    ru: '/security/log_obfuscation',
  },
  'ImportModal:helpLink-intents': {
    eng: '/platform_ux/nlu_core/intents_export_import',
    ru: '/platform_ux/nlu_core/intents_export_import',
  },
  'ImportModal:helpLink-entities': {
    eng: '/platform_ux/nlu_core/intents_export_entities',
    ru: '/platform_ux/nlu_core/intents_export_entities',
  },
  'ImportModal:helpLink-dictionary': {
    eng: '/common/bot_structure/csv',
    ru: '/common/bot_structure/csv',
  },
  'tasks modal help link editorbe.deploy.bot': {
    eng: '/channels',
    ru: '/channels',
  },
  'tasks modal help link editorbe.deploy.bot.info': {
    eng: '/channels',
    ru: '/channels',
  },
  'UserTariffLimits popover btn link uniques': {
    eng: '/account_management/plan#unique-users',
    ru: '/account_management/plan#unique-users',
  },
  'UserTariffLimits popover btn link nlu': {
    eng: '/account_management/plan#telephony-nlu',
    ru: '/account_management/plan#telephony-nlu',
    cn: '/account_management/plan#telephony-nlu',
  },
  'UserTariffLimits popover btn link asr': {
    eng: '/account_management/plan#asr-tts',
    ru: '/account_management/plan#asr-tts',
  },
  'UserTariffLimits popover btn link telephony': {
    eng: '/telephony/telephony_setup#create-a-connection',
    ru: '/telephony/telephony_setup#create-a-connection',
    cn: '/telephony/telephony_setup#create-a-connection',
  },
  'UserTariffLimits popover btn link cailarequests': {
    eng: '/NLU_core/NLP_direct_API',
    ru: '/NLU_core/NLP_direct_API',
    cn: '/NLU_core/NLP_direct_API',
  },
  'UserTariffLimits popover btn link cailarequests_tovie': {
    eng: '/NLU_core/NLP_direct_API',
    ru: '/NLU_core/NLP_direct_API',
    cn: '/NLU_core/NLP_direct_API',
  },
  'CustomAsrTtsProviderSettings:token.link': {
    eng: '/telephony/own_telephony#{{name}}-asr-tts',
    ru: '/telephony/own_telephony#{{name}}-asr-tts',
  },
  'CustomAsrTtsProviderSettings:tinkoff.token.link': {
    eng: '/telephony/own_telephony#{{name}}-asr',
    ru: '/telephony/own_telephony#{{name}}-asr',
  },
  'CreateProject:tooltip-button-link': {
    eng: '/platform_ux/create-project',
    ru: '/platform_ux/create-project',
  },
  'ProjectsDashboard: help links tutorial link': {
    eng: '/common/tutorials/simple_bot',
    ru: '/common/tutorials/simple_bot',
  },
  'ProjectsDashboard: help links course link': {
    eng: '/common/tutorials/video_course',
    ru: '/common/tutorials/video_course',
  },
  'caila project language tooltip link': {
    eng: '/NLU_functions',
    ru: '/NLU_functions',
  },
  'ProjectEditForm helpLink': {
    eng: '/platform_ux/create-project',
    ru: '/platform_ux/create-project',
  },
  'ProjectEditForm helpLink JAICF': {
    eng: '/platform_ux/jaicf/project_jaicf',
    ru: '/platform_ux/jaicf/project_jaicf',
  },
  'ProjectEditForm instruction link': {
    eng: '/platform_ux/guest_access',
    ru: '/platform_ux/guest_access',
  },
  'ProjectEditForm:GithubTokenWarning.linkHref': {
    eng: '/security/github_access_token',
    ru: '/security/github_access_token',
    cn: '/security/github_access_token',
  },
  'MailingList: help link': {
    eng: '/telephony/lists_of_numbers',
    ru: '/telephony/lists_of_numbers',
  },
  'FAQ:activationNotification:url': {
    eng: '/knowledge-base/faq#using-global-intentgroup-tag',
    ru: '/knowledge-base/faq#using-global-intentgroup-tag',
  },
  'AnswerModal:info_link': {
    eng: '/channels/restrictions',
    ru: '/channels/restrictions',
  },
  'AnswerBlocks:question_tooltip_link': {
    eng: '/knowledge-base/faq#phrases',
    ru: '/knowledge-base/faq#phrases',
  },
  'AnswerBlocks:answer_tooltip_link': {
    eng: '/channels/restrictions',
    ru: '/channels/restrictions',
  },
  'SummaryEdit:faq_link': {
    eng: '/knowledge-base',
    ru: '/knowledge-base',
  },
  'SummaryEdit:answer_link': {
    eng: '/knowledge-base/faq#answer',
    ru: '/knowledge-base/faq#answer',
  },
  'LLTopPanel:docsLink_url': {
    eng: '/platform_ux/nlu_core/dataset-creation/data-labeling',
    ru: '/platform_ux/nlu_core/dataset-creation/data-labeling',
  },
  'LogLabelingPage:spellcheck_url': {
    eng: '/platform_ux/nlu_core/entities#spelling-correction',
    ru: '/platform_ux/nlu_core/spellcheck',
  },
  'FAQ:cdqaModal:linkUrl': {
    eng: '/knowledge-base/cdqa',
    ru: '/knowledge-base/cdqa',
  },
  'ProjectEditConfig:NLU:ClassificationAlgorithm:dockLink:Link': {
    eng: '/platform_ux/nlu_core/dataset-creation/how-to-train-intents#difference-between-classifier-algorithms',
    ru: '/platform_ux/nlu_core/dataset-creation/how-to-train-intents#difference-between-classifier-algorithms',
  },
  'AnswerSettings:LinkToFormattingDocs': {
    eng: '/visual-editor/script-development/reactions/text',
    ru: '/visual-editor/script-development/reactions/text',
  },
  'BotScorer:Link': {
    eng: '/Content_testing/bot-quality-evaluation#file-with-dialog-set',
    ru: '/Content_testing/bot-quality-evaluation#file-with-dialog-set',
  },
};
