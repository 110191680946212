import React, { useCallback } from 'react';
import cn from 'classnames';

import { t } from 'localization';

import { useStageObservableContext } from 'modules/JGraph/contexts/StageObservablesProvider';
import { getValidKonvaName } from 'reducers/JGraph.reducer/Graph';

import { JGToolbarButton } from '../JGToolbar';

import styles from './styles.module.scss';

export const LevelUpButton = React.memo(() => {
  const { setSelectedGroup, selectedGroupPath, upComingSelectedGroup } = useStageObservableContext();

  const setPreviousGroup = useCallback(() => {
    if (!selectedGroupPath) return;
    const pathParts = selectedGroupPath.split('/');
    const prevGroupPath = pathParts.length >= 2 ? pathParts.slice(0, -1).join('/') : '';
    setSelectedGroup({
      path: prevGroupPath,
      pathId: getValidKonvaName(prevGroupPath),
    });
  }, [selectedGroupPath, setSelectedGroup]);

  if (!upComingSelectedGroup) return null;
  return (
    <JGToolbarButton
      className={cn(styles.LevelUpButton)}
      text={t('CurrentGroupPath:UpInScenario')}
      iconName='farArrowToTop'
      onClick={() => setPreviousGroup()}
      data-test-id='JGToolbar:UpInScenario'
    />
  );
});
LevelUpButton.displayName = 'LevelUpButton';
