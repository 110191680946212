import React, { FC, useEffect, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from '../../../storeHooks';
import { Portal, Spinner, useBehaviorSubject } from '@just-ai/just-ui';
import { lazyActionsPipe$, useJGraphBaseLoading } from '../hooks';

import { cleanUp, onDebugMessageReceived, setEditMenuBlock } from 'reducers/JGraph.reducer';

import { ActionsAddingMenu } from './ActionsAddingMenu';
import { StateNameEditField } from './StateNameEditField';

import { RightSideMenuWrapper } from './RightSideMenu';
import { SavingPipeContext, useJGraphSavingPipe } from '../hooks/savingPipe';

import './JGraph.scss';
import { CreationScreenMenu } from './CreationScreenMenu';

import StageObservablesProvider from '../contexts/StageObservablesProvider';

import { StageView } from './StageView';
import { StageInGroupView } from './StageInGroupView';
import { CurrentGroupPath } from './CurrentGroupPath';
import { JGraphLoadError } from './JGraphLoadError';

import { debugDataPipe$, debugDataState$, JWidgetMessageLocator } from '../pipes/debugDataPipe';
import { findScreenByCondition } from 'reducers/JGraph.reducer/Graph';
import { DebugIsRunning } from './DebugIsRunning';
import { NeedToRedeployWidget } from './NeedToRedeployWidget';

import EmptyJgraphView from 'modules/JGraph/view/EmptyJgraphView';
import EmptyWarningBanner from './EmptyWarningBanner';
import EmptyNoMatchBanner from './EmptyNoMatchBanner';

import { t } from 'localization';
import { amplitudeInstance } from 'pipes/functions';
import { MoveStateInGroup } from './MoveStateInGroup';
import { isStateType } from 'modules/JGraph/utils/isStateType';

import RenderingBlockWrapper from './RenderingModal/RenderingBlockWrapper';
import { AutoLayoutProgressSubject$ } from '../hooks/useAutoplacement';
import { highLightConnectors$ } from '../hooks/highLightConnectors';
import TextEditLayer from './Sticker/TextEditLayer';
import KonvaTooltipArea from '../components/KonvaTooltipArea';
import ContextMenu from './ContextMenu';

type JGraphViewProps = {};

export const JGraphView: FC<JGraphViewProps> = React.memo(() => {
  const {
    loadingGraph,
    loadingCustomTags,
    stateNameInputValue,
    screens,
    stickers,
    error,
    reverting,
    isEditModeEnable,
  } = useAppSelector(state => ({
    isEditModeEnable: state.JGraphReducer.isEditModeEnable,
    error: state.JGraphReducer.error,
    screens: state.JGraphReducer.graph.blocks,
    stickers: state.JGraphReducer.stickers,
    reverting: state.JGraphReducer.graph.reverting,
    loadingGraph: state.JGraphReducer.loadingGraph,
    loadingCustomTags: state.JGraphReducer.loadingCustomTags,
    stateNameInputValue: state.JGraphReducer.stateNameInputValue,
  }));
  const dispatch = useAppDispatch();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const loaderShowed = React.useRef<boolean>(false);

  useJGraphBaseLoading();

  useEffect(() => {
    amplitudeInstance.logEvent('J-Graph activated');

    return () => {
      highLightConnectors$.next({ connector: null });
    };
  }, []);

  useEffect(() => {
    const main = document.querySelector('.editor__main-toolbar');
    if (main) {
      //@ts-ignore
      main.classList.add('visual');
    }
    return () => {
      dispatch(setEditMenuBlock(undefined));
      dispatch(cleanUp());
      if (main) {
        //@ts-ignore
        main.classList.remove('visual');
      }
    };
  }, [dispatch]);

  useEffect(() => {
    let sub = lazyActionsPipe$.subscribe(next => console.log(next));
    let sub2 = debugDataPipe$.subscribe(next => {
      dispatch(onDebugMessageReceived({ whatToHighlight: next.lastAction }));
    });

    const onJustWidgetMessage = (event: any) => {
      // console.log(event.detail?.text?.debugData);
      if (event.detail?.text?.debugData) {
        debugDataState$.next(event.detail?.text?.debugData as JWidgetMessageLocator[]);
      }
    };

    document.addEventListener('justwidget_get_message', onJustWidgetMessage);
    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
      document.removeEventListener('justwidget_get_message', onJustWidgetMessage);
    };
  }, [dispatch]);

  const { saveMove, stateUpdate, stateCreate } = useJGraphSavingPipe();

  const startState = useMemo(() => screens.find(screen => isStateType(screen, 'start')), [screens]);

  const isExistNoMatchState = useMemo(() => {
    const noMatchInRoot = screens.some(screen => isStateType(screen, 'noMatch'));
    const globalNoMatchInDeep = findScreenByCondition(screens, screen => isStateType(screen, 'noMatchGlobal'));
    return noMatchInRoot || globalNoMatchInDeep;
  }, [screens]);
  const dontHaveScreens = screens && screens.length === 0;

  useEffect(() => {
    if (!loaderShowed.current && !(loadingGraph || loadingCustomTags) && !error) {
      RenderingBlockWrapper.State$.next({ cancellable: false, title: t('RenderingModal:BuildingScenarioTitle') });
      RenderingBlockWrapper.Percentage$.next({
        type: 'start',
        total: screens.filter(screen => screen.hasOwnProperty('canRender')).length,
      });
      loaderShowed.current = true;
    }
  }, [error, loadingCustomTags, loadingGraph, screens]);

  const autoLayoutProgress = useBehaviorSubject(AutoLayoutProgressSubject$);

  if (loadingGraph || loadingCustomTags) return <Spinner />;
  if (isEditModeEnable && error) return <JGraphLoadError error={error} />;

  return (
    <>
      {dontHaveScreens ? (
        <EmptyJgraphView fixable={isEditModeEnable} />
      ) : !startState ? (
        <EmptyWarningBanner fixable={isEditModeEnable} title={t('EmptyMode:WarningTitle')} />
      ) : !isExistNoMatchState ? (
        <EmptyNoMatchBanner fixable={isEditModeEnable} title={t('EmptyMode:NotExist:WarningTitle')} />
      ) : null}
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexWrap: 'nowrap',
          position: 'relative',
          outline: 'none',
          margin: 0,
        }}
        ref={containerRef}
        // onBlur={handleBlur}
        tabIndex={0}
      >
        <SavingPipeContext.Provider
          value={{
            saveMove,
            stateCreate,
            stateUpdate,
          }}
        >
          <div className='Jgraph-container' id='jgraph-wrapper'>
            <div className='debugDiv' />
            {(reverting || autoLayoutProgress.status === 'pending') && <Spinner />}
            <MoveStateInGroup />
            <ActionsAddingMenu />
            <NeedToRedeployWidget />
            <TextEditLayer />
            <div id='JGraphStatusWidget' />
            {stateNameInputValue && <StateNameEditField />}
            <DebugIsRunning />
            <RightSideMenuWrapper />
            <StageObservablesProvider startState={startState} screens={screens} stickers={stickers}>
              <CurrentGroupPath />
              <CreationScreenMenu />
              <ContextMenu />
              <StageView containerRef={containerRef} screens={screens} />
              <StageInGroupView />
            </StageObservablesProvider>
          </div>
        </SavingPipeContext.Provider>
      </div>
      <Portal targetNodeSelector='body'>
        <KonvaTooltipArea />
      </Portal>
    </>
  );
});
JGraphView.displayName = 'JGraphView';
