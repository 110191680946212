import { loadStripe } from '@stripe/stripe-js/pure';
import history from 'appHistory';

export const redirectToPaymentSystem = data => {
  if (data.redirectUrl && data.paymentSystem === 'YANDEX_CASH') {
    window.location.href = data.redirectUrl;
  } else if (data.paymentSystem === 'STRIPE' && data.stripePublicKey && data.stripeSessionId) {
    let stripe = loadStripe(data.stripePublicKey);
    stripe.then(payload => {
      payload && payload.redirectToCheckout({ sessionId: data.stripeSessionId });
    });
  } else {
    history.push('/accountmanager');
  }
};
