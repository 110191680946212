import { forEach } from 'lodash';
import { reduxStateReference } from 'services/ReduxStateReference';
import { channelTypeList, operatorChannelTypeList } from 'constants/channelTypes';

export default function isAccess(permissionsOrFeatures) {
  if (!permissionsOrFeatures) return false;
  if (Array.isArray(permissionsOrFeatures)) {
    return permissionsOrFeatures.every(permissionOrFeature =>
      permissionOrFeature.startsWith('!')
        ? !isAccessOrEnabledFunction(permissionOrFeature.slice(1))
        : isAccessOrEnabledFunction(permissionOrFeature)
    );
  }

  if (typeof permissionsOrFeatures === 'string') {
    if (permissionsOrFeatures.startsWith('!')) {
      return !isAccessOrEnabledFunction(permissionsOrFeatures.slice(1));
    }
    return isAccessOrEnabledFunction(permissionsOrFeatures);
  }

  return false;
}

function isAccessOrEnabledFunction(permissionOrFeature) {
  return isAccessFunction(permissionOrFeature) || isEnabledFunction(permissionOrFeature);
}

export function hasFeatures(...features) {
  return features.every(isEnabledFunction);
}

export function isSystemAccess(systemFeatureOrFeatureList) {
  const state = reduxStateReference.getState();

  const systemFeatures = state.AppConfigReducer.systemFeatures;

  if (Array.isArray(systemFeatureOrFeatureList)) {
    return systemFeatureOrFeatureList.every(systemFeatureName => {
      return systemFeatures.hasOwnProperty(systemFeatureName) && systemFeatures[systemFeatureName];
    });
  } else {
    return systemFeatures.hasOwnProperty(systemFeatureOrFeatureList) && systemFeatures[systemFeatureOrFeatureList];
  }
}

export function isTextCampaignEnabled() {
  return hasFeatures('text-campaign') && isSystemAccess(['outbound_messages_licensed']);
}

function getCurrentUserData() {
  const state = { ...reduxStateReference.getState() };

  let currentUserData = null;

  if (!state.CurrentUserReducer.currentUser) {
    try {
      currentUserData = JSON.parse(localStorage.CURRENT_USER);
    } catch (e) {
      currentUserData = state.CurrentUserReducer;
    }
  } else {
    currentUserData = state.CurrentUserReducer;
  }
  // }
  return currentUserData;
}

export function isInternal() {
  const currentUserData = getCurrentUserData();

  return Boolean(currentUserData) ? currentUserData.internal : false;
}

export function isAccountManager() {
  const currentUserData = getCurrentUserData();

  return Boolean(currentUserData) ? currentUserData.roles.some(role => role.name === 'ACCOUNTS_MANAGER') : false;
}

export function isSAdmin() {
  const currentUserData = getCurrentUserData();

  return Boolean(currentUserData) ? currentUserData.roles.some(role => role.name === 'ACCOUNTS_ADMIN') : false;
}

export function isAccountUser() {
  const currentUserData = getCurrentUserData();

  return Boolean(currentUserData) ? currentUserData.roles.some(role => role.name === 'ACCOUNTS_USER') : false;
}

export function isContentManager() {
  const currentUserData = getCurrentUserData();

  return Boolean(currentUserData) ? currentUserData.roles.some(role => role.name === 'CONTENT_MANAGER') : false;
}

/**
 * @return {boolean}
 */
export function isAccountAdmin() {
  const currentUserData = getCurrentUserData();

  return Boolean(currentUserData) ? currentUserData.roles.some(role => role.name === 'ADMIN') : false;
}

export function isInternalWrite() {
  return isInternal() && isAccess(['ACCOUNTS_ADMIN_WRITE']);
}

export function isLoggedInAccount() {
  const state = { ...reduxStateReference.getState() };
  let loginInAccount = state.AccountsReducer.loggedInAccount;

  if (!loginInAccount) {
    try {
      loginInAccount = JSON.parse(localStorage.getItem('LOGIN_TO_ACCOUNT'));
    } catch (e) {
      loginInAccount = null;
    }
  }

  return !!loginInAccount;
}

function isAccessFunction(str) {
  if (str === 'ALL') {
    //todo migrate this to serverside
    return true;
  }
  try {
    //TODO remove localstorage hack?
    let state = reduxStateReference.getState();
    let allPermissions = state.CurrentUserReducer.allPermissions;
    if (allPermissions.length === 0) {
      allPermissions = JSON.parse(localStorage.CURRENT_USER).allPermissions;
    }
    if (Boolean(state.AccountsReducer.loggedInAccount)) {
      const hasPermission = allPermissions.some(permission => {
        return permission.name === str;
      });
      const hasAccountsLoginReadPermission = allPermissions.some(permission => {
        return permission.name === 'ACCOUNTS_LOGIN_READ';
      });
      return hasAccountsLoginReadPermission && hasPermission;
    } else {
      return allPermissions.some(permission => {
        return permission.name === str;
      });
    }
  } catch (e) {
    return false;
  }
}

export function isConfigEnabled(configName) {
  try {
    const state = { ...reduxStateReference.getState() };
    return state.AppConfigReducer.botadmin[configName] || false;
  } catch (e) {
    return false;
  }
}

export function isReporterEnabled() {
  try {
    const state = { ...reduxStateReference.getState() };
    return state.AppConfigReducer.isReporterEnabled;
  } catch (e) {
    return false;
  }
}

export function getImputerUrl() {
  try {
    const state = { ...reduxStateReference.getState() };
    return state.AppConfigReducer.imputerExternalUrl;
  } catch (e) {
    return '';
  }
}

export function isChatWidgetSecurityEnabled() {
  try {
    const state = { ...reduxStateReference.getState() };
    return state.AppConfigReducer.chatWidgetSecurityEnabled;
  } catch (e) {
    return false;
  }
}

function isEnabledFunction(featureName) {
  try {
    const state = { ...reduxStateReference.getState() };
    let allFeatures = [];
    if (isInternal() && Boolean(state.AccountsReducer.loggedInAccount)) {
      allFeatures = state.AccountsReducer.loggedInAccountFeatures;
      if (allFeatures.length === 0) {
        allFeatures = JSON.parse(localStorage.getItem('LOGIN_TO_ACCOUNT_FEATURES'));
      }
    } else {
      allFeatures = state.CurrentUserReducer.features;
      if (allFeatures.length === 0) {
        allFeatures = JSON.parse(localStorage.getItem('CURRENT_USER')).features;
      }
    }

    return allFeatures.some(feature => {
      return feature.name === featureName;
    });
  } catch (e) {
    return false;
  }
}

export function isLoggedIn() {
  let localStorageCurUser = null;
  try {
    localStorageCurUser = JSON.parse(localStorage.CURRENT_USER);
  } catch (e) {
    localStorageCurUser = null;
  }
  const currentUser = reduxStateReference.getState().CurrentUserReducer.currentUser || localStorageCurUser;
  return !!currentUser;
}

export const getAvailableChannels = () => {
  const state = { ...reduxStateReference.getState() };
  const availableChannels = {};
  const currentProjectShortName = state.CurrentProjectsReducer.currentProject;
  const currentProject = state.ProjectsReducer.projectList.find(item => item.shortName === currentProjectShortName);
  const isJaicfProject = currentProject?.jaicfProject;
  const isZBApiUser = isAccess('zbapi.user');
  const jaicfChannels = [
    'CHAT_API',
    'RESTERISK',
    'CHAT_WIDGET',
    'GOOGLE',
    'FACEBOOK',
    'INSTAGRAM',
    'TELEGRAM',
    'JAICP_ALEXA',
    'ZENBOX',
    'DIALOGFLOW',
    'SLACK',
    'YANDEX',
    'VIBER',
  ];
  const jaicfOnly = ['jaicp_alexa', 'dialogflow'];
  const jaicfFilter = channelName => {
    return jaicfChannels.includes(channelName);
  };

  let botadminConfigExcludedChannels =
    Boolean(state.AppConfigReducer.botadmin) && Boolean(state.AppConfigReducer.botadmin.channels)
      ? [...state.AppConfigReducer.botadmin.channels, ...jaicfOnly]
      : [...jaicfOnly];

  botadminConfigExcludedChannels = botadminConfigExcludedChannels.map(item => {
    switch (item) {
      case 'chatapi':
        return 'chat_api';
      case 'chatwidget':
        return 'chat_widget';
      case 'incoming_webim':
        return 'webim';
      case 'levetex_prompter':
        return 'livetex_prompter';
      default:
        return item;
    }
  });

  forEach(channelTypeList, (channelList, groupName) => {
    availableChannels[groupName] = channelList.filter(channelType => {
      if (isZBApiUser) return true;
      if (isJaicfProject) return jaicfFilter(channelType);
      return !botadminConfigExcludedChannels.includes(channelType.toLowerCase());
    });
  });

  return availableChannels;
};

export const isChannelNotBlocked = channel => {
  const state = { ...reduxStateReference.getState() };
  const zenflowConfigExcludedChannels =
    Boolean(state.AppConfigReducer.botadmin) && Boolean(state.AppConfigReducer.botadmin.channels)
      ? state.AppConfigReducer.botadmin.channels
      : [];
  return zenflowConfigExcludedChannels.includes(channel?.toLowerCase()) === false;
};

export const getAvailableOperatorChannels = () => {
  const state = { ...reduxStateReference.getState() };

  const botadminConfigExcludedOperatorsChannels =
    Boolean(state.AppConfigReducer.botadmin) && Boolean(state.AppConfigReducer.botadmin.livechatChannels)
      ? state.AppConfigReducer.botadmin.livechatChannels
      : [];

  return operatorChannelTypeList.filter(channelType => {
    return !botadminConfigExcludedOperatorsChannels.includes(channelType.toLowerCase());
  });
};

export const excludeUnavailableTags = tags => {
  const state = { ...reduxStateReference.getState() };

  let unavailableTags = state.AppConfigReducer.zenflow?.unavailableBlocks || [];
  unavailableTags = unavailableTags.map(tagName => tagName.toLowerCase());

  return tags.filter(tag => !unavailableTags.includes(tag.tagName.toLowerCase()));
};

export const isWhiteLabel = () => {
  const state = { ...reduxStateReference.getState() };

  return (
    state.AppConfigReducer.botadmin.productName?.length > 0 && state.AppConfigReducer.botadmin.productName !== 'JAICP'
  );
};

export const isEuroInstance = () => {
  try {
    const state = { ...reduxStateReference.getState() };
    return state.AppConfigReducer.botadmin.euroInstance || false;
  } catch (e) {
    return false;
  }
};

export const showHelp = () => {
  const state = { ...reduxStateReference.getState() };

  return state.AppConfigReducer.botadmin.showHelp;
};

export function isLanguagesAvailable(languages) {
  try {
    const state = { ...reduxStateReference.getState() };

    let systemLanguages = state.AppConfigReducer.botadmin?.availableLanguages || ['en', 'ru', 'cn'];

    return languages.filter(x => systemLanguages.includes(x === 'eng' ? 'en' : x));
  } catch (e) {
    return e;
  }
}

export const isCcSessionsAreEnabled = () => {
  return reduxStateReference.getState().AppConfigReducer.ccSessionsAreEnabled;
};
