import { Vector2d } from 'konva/lib/types';
import { current, Draft, isDraft } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { CustomTagParameterType, JGraphApi, ProjectApi, JGraphStickersApi } from '@just-ai/api/dist/generated/Editorbe';

import { axios } from 'pipes/functions';

import {
  MapTagParamOnTagName,
  ReactionsTagNamesWithElseif,
  TagNames,
  TJBlock,
  TTagParameters,
} from '../../modules/JGraph/utils/types';
import { JBlockLocator } from '../../modules/Editor/api/client';

import { FromStateTransitions, JGraphStateData, JStateWithId } from './types';
import {
  findScreenByPathId,
  FromStateConnectionsStore,
  getAllInnerStates,
  getConnectionsFromBlocks,
  getValidKonvaName,
} from './Graph';
import { CustomTagsStore$ } from './customTags.store';

export const GraphApi = new JGraphApi({}, '', axios);
export const jGraphStickersApi = new JGraphStickersApi({}, '', axios);
export const ProjectApiService = new ProjectApi({}, '', axios);
export const makeFromStateConnections = (screenToSave: Draft<JStateWithId>, graph: Draft<JGraphStateData>) => {
  const subStates = getAllInnerStates(screenToSave).map(state => state.pathId);
  cleanFromStateMapConnections(graph.fromStateTransitions, [screenToSave.pathId, ...subStates]);
  const newStore = new FromStateConnectionsStore({ ...current(graph.fromStateTransitions) });
  const { fromStateTransitions } = getConnectionsFromBlocks(
    [isDraft(screenToSave) ? current(screenToSave) : screenToSave],
    newStore
  );
  graph.fromStateTransitions = fromStateTransitions;
};

export const cleanFromStateMapConnections = (
  fromStateTransitions: Draft<FromStateTransitions>,
  screenPathIds: string[]
) => {
  for (let from in fromStateTransitions) {
    if (fromStateTransitions.hasOwnProperty(from)) {
      fromStateTransitions[from] = fromStateTransitions[from].filter(element => {
        return !screenPathIds.includes(element.to);
      });
    }
  }
};

export function createBlocks(blocks: TJBlock['tagName'][]): TJBlock[] {
  return blocks.reduce((acc, block) => {
    acc.push({
      tagName: block,
      tagParameters: createBlockTagParameters(block),
      tagValue: '',
      jblocks: [],
    });
    return acc;
  }, [] as TJBlock[]);
}

const getDefaultValueOnParamType = (paramType: CustomTagParameterType, tagName?: TagNames) => {
  if (tagName === TagNames.Email && paramType === CustomTagParameterType.Json) {
    return '[]';
  }

  switch (paramType) {
    case CustomTagParameterType.String:
    case CustomTagParameterType.Integer:
      return '';
    case CustomTagParameterType.Bool:
      return false;
    case CustomTagParameterType.NameValueList:
      return [{ name: '', value: '' }];
    case CustomTagParameterType.Parameters:
      return {};
    case CustomTagParameterType.Intents:
    case CustomTagParameterType.StringArray:
      return [''];
    // @ts-ignore TODO ZB-25012 switch to CustomTagParameterType enum
    case 'select':
      return '';
    case CustomTagParameterType.Html:
      return '';
    case CustomTagParameterType.Json:
      return '{}';
    case CustomTagParameterType.State:
      return null;
  }
};
export function getCustomTagParametersFieldsType(tagName: TJBlock['tagName']) {
  const customTagsDescriptors = CustomTagsStore$.getValue();
  const exitsDescriptor = customTagsDescriptors?.find(descript => descript.tagName === tagName);
  if (exitsDescriptor) {
    return exitsDescriptor.parameters?.reduce((acc, currentValue) => {
      acc[currentValue.name!] = currentValue.type || CustomTagParameterType.String;
      return acc;
    }, {} as Record<string, CustomTagParameterType>);
  }
  return {} as Record<string, CustomTagParameterType>;
}
export function createBlockTagParameters(tagName: TJBlock['tagName']): TTagParameters<string, any>[] {
  const customTagsDescriptors = CustomTagsStore$.getValue();

  switch (tagName) {
    case TagNames.a:
      return [
        {
          name: 'htmlEnabled',
          required: false,
          value: null,
        },
        {
          name: 'html',
          required: false,
          value: null,
        },
        {
          name: 'lang',
          required: false,
          value: null,
        },
        {
          name: 'ttsEnabled',
          required: false,
          value: null,
        },
        {
          name: 'tts',
          required: false,
          value: null,
        },
        {
          name: 'alexaVoiceEnabled',
          required: false,
          value: null,
        },
        {
          name: 'alexaVoice',
          required: false,
          value: null,
        },
      ] as MapTagParamOnTagName[typeof tagName];
    case TagNames.if:
    case TagNames.else:
    case TagNames.elseif:
      return [];
    default:
      const exitsDescriptor = customTagsDescriptors?.find(descript => descript.tagName === tagName);
      if (exitsDescriptor) {
        return exitsDescriptor.parameters?.map(param => ({
          name: param.name,
          required: param.required,
          value: getDefaultValueOnParamType(param.type || CustomTagParameterType.String, tagName),
        })) as TTagParameters<string, any>[];
      }
      return [];
  }
}

export function getParentPaths(statePath: string, theme: string) {
  const statePaths = statePath.split('/');
  const parentStatePath = statePaths.slice(0, -1).join('/') || undefined;
  const parentThemePath = statePaths.length >= 3 ? statePaths[statePaths.length - 3] : theme;
  return {
    parentStatePath,
    parentThemePath,
  };
}

export function renameNameInPath(statePath: string, newName: string) {
  const statePaths = statePath.split('/');
  return [...statePaths.slice(0, -1), newName].join('/');
}

export function restoreStateToStateBlocksInPlace(blocks: JStateWithId[], state: JStateWithId) {
  const existedScreen = findScreenByPathId(state.pathId, blocks);
  if (existedScreen) {
    const index = blocks.findIndex(screen => screen.pathId === state.pathId);
    blocks.splice(index, 1, {
      ...state,
      x: existedScreen.x,
      y: existedScreen.y,
    });
    return;
  }
  blocks.push(state);
}

export function createNewState(
  path: string,
  blocks: TJBlock['tagName'][],
  screenPosition: Vector2d,
  parentScreen?: JStateWithId,
  filename?: string
): JStateWithId {
  let screenFullPath = parentScreen ? [parentScreen.path, path].join(`/`) : path;
  screenFullPath = screenFullPath.replace(/\/+/g, '/');
  if (!screenFullPath.startsWith('/')) {
    screenFullPath = `/${screenFullPath}`;
  }
  let screen: JStateWithId = {
    theme: parentScreen?.theme || '/', //TODO use context\parent theme on create
    path: screenFullPath,
    pathId: getValidKonvaName(screenFullPath),
    filename: parentScreen?.filename || filename || '/src/main.sc',
    id: uuid(),
    blocks: createBlocks(blocks),
    value: path.startsWith('/') ? path.substring(1) : path,
    x: screenPosition.x,
    y: screenPosition.y,
    isUnsaved: true,
    states: [],
  };

  return screen;
}

export function tagParametersToObj(tagParameters: TTagParameters<any, any>[]) {
  let obj: Record<string, TTagParameters<string, any>> = {};
  tagParameters.forEach(tagParam => {
    obj[tagParam.name] = tagParam;
  });
  return obj;
}

export function getPositionBeforeToStateBlocks(blocks: TJBlock[]) {
  let positionBefore = 0;
  let isBefore = false;
  for (let i = 0; i < blocks.length; i++) {
    if (ReactionsTagNamesWithElseif.includes(blocks[i].tagName)) {
      isBefore = true;
    }
    if (isBefore && !ReactionsTagNamesWithElseif.includes(blocks[i].tagName)) {
      positionBefore = i;
      break;
    }
    positionBefore = i + 1;
  }
  return positionBefore;
}

export function recursiveCleanUpDebugData(blocks: TJBlock[]) {
  blocks.forEach(jblock => {
    if (jblock.tagName === TagNames.buttons) {
      jblock.tagParameters.forEach(param => {
        if (param.debugActive) {
          delete param.debugActive;
        }
      });
    }
    if (startsWithCapital(jblock.tagName)) {
      jblock.tagParameters.forEach(param => {
        if (param.debugActive) {
          delete param.debugActive;
        }
      });
    }
    if (jblock.debugActive) {
      delete jblock.debugActive;
    }
    if (jblock.debugTransition) {
      delete jblock.debugTransition;
    }
    if (jblock.jblocks) {
      recursiveCleanUpDebugData(jblock.jblocks);
    }
  });
}
export function fillJBlocksPathByLine(
  fileNameLines: Record<number, number[]>,
  parentPath: number[],
  tagLocators: JBlockLocator[]
) {
  tagLocators.forEach((tagLocator, index) => {
    const newPath = [...parentPath, index];
    fileNameLines[tagLocator.locator.line] = newPath;
    if (tagLocator.jblocks) {
      fillJBlocksPathByLine(fileNameLines, newPath, tagLocator.jblocks);
    }
  });
}

export function startsWithCapital(word: string) {
  return word.charAt(0) === word.charAt(0).toUpperCase();
}

export function moveBlocksFromDescendingTags(currentBlock: TJBlock, blockIndex: number, parentBlocks: TJBlock[]) {
  let iterator = blockIndex + 1;
  const newJBlocks = [...parentBlocks];
  while (iterator < parentBlocks.length) {
    const nextBlock = parentBlocks[iterator];
    if ([TagNames.else, TagNames.elseif].includes(nextBlock.tagName)) {
      //move blocks from next to current;
      nextBlock.jblocks.forEach(block => currentBlock.jblocks.push(block));
      newJBlocks.splice(blockIndex + 1, 1);
    } else {
      break;
    }
    iterator++;
  }
  return newJBlocks;
}

const getNewStateName = (statesNames: string[], path: string, iterator: number): string => {
  if (statesNames.includes(`${path}_${iterator}`)) {
    return getNewStateName(statesNames, path, ++iterator);
  }
  return `${path}_${iterator}`;
};

export function checkNameExistInGroup(stateIntoMove: JStateWithId[], stateIntoMovePath: string, path: string) {
  const nameMap = stateIntoMove.map(state => state.path.slice(stateIntoMovePath.length, state.path.length));
  const isExist = nameMap.includes(path);
  let guessName = path;
  if (isExist) {
    guessName = getNewStateName(nameMap, path, 1);
  }

  return {
    isExist,
    guessName,
  };
}
