import React, { Component } from 'react';
import { Spinner } from '@just-ai/just-ui';
import cloneDeep from 'lodash/cloneDeep';
import GlobalBottomPanel from '../../components/GlobalBottomPanel';
import Sidebar from '../../components/Sidebar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loginActions from '../../actions/currentUser.actions';
import { getCurrentUser } from '../../actions/currentUser.actions';
import { getTotalUserTasksCount } from '../../actions/tasks.actions';
import { addMessage, dismissAllAlerts, dismissMessage } from '../../actions/globalAlert.actions';
import { getCurrentProject, loadProjects, unsetCurrentProject } from '../../actions/projects.actions';
import {
  getCurrentLoginAccount,
  loginToAccount,
  logoutFromAccount,
  setCurrentLoginAccount,
} from '../../actions/accounts.actions';
import localize, { t } from '../../localization';
import isAccess, { isAccountAdmin, isInternal, isLoggedInAccount } from '../../isAccessFunction';
import classNames from 'classnames';
import GlobalRightPanel from '../../components/GlobalRightPanel';
import { TestWidgetContextProvider } from '../../modules/TestWidget';
import history from '../../appHistory';
import { fetchUserData } from '../../actions/accountManager.actions';
import { setChannelToPublish, publishChannel } from 'actions/channels.actions';
import { GlobalAlerts } from './GlobalAlerts';
import './style.scss';
import { HeaderArea } from './HeaderArea';
import { GlobalRightPanelContextProvider } from 'components/GlobalBottomPanel/GlobalBottomPanelContext';
import queryString from 'query-string';
import { getAppStatus } from '../../actions/appConfig.actions';
import { hasGithubTokenWarning } from 'views/ProjectEditForm/steps/RepoLocation';
import { AppContext } from 'modules/Caila/components/AppContext';
import withAmplitude from '../../utils/withAmplitude';
import { amplitudeInstance, isDev } from '../../pipes/functions';
import ProjectGroupLeaveModal from '../../modules/ProjectGroups/components/ProjectGroupLeaveModal';

const INNER_EVENT = 'INNER_EVENT';
const UPDATE_TASK_COUNT_EVENT = 'UPDATE_TASK_COUNT_EVENT';

class Full extends Component {
  static contextType = AppContext;

  sockets = false;
  state = {
    load: false,
    showGithubWarning: false,
  };

  getProjectsListInner = () => {
    const { BotProjectsService, setOriginalProjects } = this.context;
    return BotProjectsService.getProjects().then(data => {
      const currentProjects = data.data;
      if (currentProjects) {
        setOriginalProjects(cloneDeep(currentProjects));
      }
      return data;
    });
  };

  componentWillMount() {
    const {
      currentUser,
      loggedInAccount,
      projectShortName,
      //NOSONAR
      getCurrentLoginAccount,
      //NOSONAR
      getCurrentProject,
      //NOSONAR
      loginActions,
      getProjectsList,
      location,
      addMessage,
      ccSessionsAreEnabled,
    } = this.props;

    const parsedLocationSearch = queryString.parse(location.search);
    const autoLoginToken = parsedLocationSearch?.autoLoginToken;

    const load = async () => {
      if (!projectShortName) {
        await getCurrentProject();
      }

      if (!currentUser || autoLoginToken) {
        try {
          await loginActions.login(null, autoLoginToken);
        } catch (e) {
          const error = e.response?.data?.errorCode;
          if (error?.includes('autoLoginToken')) {
            history.push({ search: `errorCode=${e.response.data.errorCode}` });
            addMessage({
              type: 'error',
              message: t(`Login:BE-error ${error}`),
            });
          }
        }
      }
      if (!loggedInAccount) {
        await getCurrentLoginAccount();
      }

      if (isInternal()) this.props.getAppStatus();

      if (
        ccSessionsAreEnabled &&
        this.props.currentUser &&
        !this.props.currentUser.emailVerified &&
        this.props.currentUser.emailVerificationRequired &&
        !isDev()
      ) {
        window.location.href = '/c/verify-email';
      }

      if (!isInternal() || isLoggedInAccount()) {
        if (this.props.currentUser) {
          await getProjectsList(this.getProjectsListInner);
        }
      }
    };

    load().then(() => {
      try {
        loginActions.getUserSubscription();
      } catch (e) {
        console.log(e);
      }
      this.setState({
        load: true,
        showGithubWarning: this.hasGithubTokenWarning(),
      });
    });
  }

  sendDataToAmplitude = () => {
    const { currentUser, adminUser, tariffData } = this.props;
    if (currentUser || adminUser) {
      amplitudeInstance.setUserId(currentUser?.id || adminUser?.currentUser?.id);
      amplitudeInstance.setUserProperties({
        user_id: currentUser?.id || adminUser?.currentUser?.id,
        is_super_account: isInternal(),
        is_logged_in_account: isLoggedInAccount(),
        work_user_id: adminUser?.currentUser?.id,
        is_just_ai_account:
          currentUser?.email || adminUser?.currentUser?.email
            ? (currentUser?.email || adminUser?.currentUser?.email)?.endsWith('just-ai.com')
            : 'email_not_defined',
        server_id: window.location.hostname,
        tariff: tariffData?.tariffName,
      });
    }
  };

  handleInnerEvent = body => {
    const {
      detail: { code, title, type, time },
    } = body;
    this.props.addMessage({ type, title: t(title), message: t(code), time: time || +new Date(), showed: false });
  };

  getAccountData = async accountId => {
    const { ccSessionsAreEnabled, userNeedToSpecifyCountryIsoCode, currentUser } = this.props;
    if (accountId) {
      try {
        await this.props.fetchUserData(accountId);
        if (
          ccSessionsAreEnabled &&
          userNeedToSpecifyCountryIsoCode &&
          currentUser &&
          !currentUser.internal &&
          currentUser.accountOwner &&
          !this.props.countryIsoCode &&
          !isDev()
        ) {
          window.location.href = '/c/select-country';
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  componentDidMount() {
    const { currentUser, location, loggedInAccount } = this.props;
    if (isInternal()) this.getAccountData(loggedInAccount?.accountId);
    if (
      currentUser &&
      currentUser.account &&
      (isAccess(['SPECIFIC_ACCOUNT_MANAGEMENT']) || isAccess(['ACCOUNTS_TARIFF_READ']))
    ) {
      this.getAccountData(currentUser.account.id);
    }
    if (isInternal() && !isLoggedInAccount()) {
      location?.state?.from === 'login' && amplitudeInstance.logEvent('Login accomplished with email');
      document.body.classList.add('sidebar-hidden');
      this.sockets = false;
    } else {
      if (currentUser && amplitudeInstance) {
        location?.state?.from === 'login' && amplitudeInstance.logEvent('Login accomplished with email');
        location?.state?.from === 'Register' && amplitudeInstance.logEvent('Register with email accomplished');
        !location?.state?.from && amplitudeInstance.logEvent('Login possibly accomplished with Google\\Github');
      }
      document.body.classList.remove('sidebar-hidden');
    }
    this.sendDataToAmplitude();

    window.addEventListener(INNER_EVENT, this.handleInnerEvent);
    window.addEventListener(UPDATE_TASK_COUNT_EVENT, this.updateTaskCount);
    window.addEventListener('changeProjectGroupCookie', this.changeProjectGroup);
  }

  componentDidUpdate(prevProps) {
    const { currentUser, projectShortName, getProjectsList, loggedInAccount } = this.props;
    if (!currentUser) return;

    if (!prevProps.loggedInAccount && loggedInAccount) {
      this.getAccountData(loggedInAccount?.accountId);
    }
    if (
      currentUser !== prevProps.currentUser &&
      currentUser &&
      currentUser.account &&
      (isAccountAdmin() || isAccess(['SPECIFIC_ACCOUNT_MANAGEMENT']) || isAccess(['ACCOUNTS_TARIFF_READ']))
    ) {
      this.getAccountData(this.props.currentUser.account.id);
    }

    if (prevProps.projectShortName && !projectShortName && loggedInAccount) {
      getProjectsList(this.getProjectsListInner).then(() => {
        const hasGithubTokenWarning = this.hasGithubTokenWarning();
        if (this.hasGithubTokenWarning() !== this.state.showGithubWarning)
          this.setState({ showGithubWarning: hasGithubTokenWarning });
      });
    } else {
      const hasGithubTokenWarning = this.hasGithubTokenWarning();
      if (this.hasGithubTokenWarning() !== this.state.showGithubWarning)
        this.setState({ showGithubWarning: hasGithubTokenWarning });
    }

    if (isInternal() && !isLoggedInAccount()) {
      this.sockets = false;
      document.body.classList.add('sidebar-hidden');
    } else {
      document.body.classList.remove('sidebar-hidden');
    }
    this.sendDataToAmplitude();
  }

  componentWillReceiveProps(nextProps) {
    const { fetchTasksCount, getProjectsList } = this.props;
    const { currentUser, projectShortName } = nextProps;

    if (currentUser?.account?.id !== this.props.currentUser?.account?.id) {
      getProjectsList(this.getProjectsListInner);
    }

    const currentLang = this.props.language;
    const nextLang = nextProps.language;
    if (currentLang !== nextLang) {
      localize.setLocale(nextLang);
    }

    if (!!projectShortName && !!currentUser && !!this.props.currentUser && !!this.props.projectShortName) {
      if (currentUser.id !== this.props.currentUser.id || projectShortName !== this.props.projectShortName) {
        fetchTasksCount(projectShortName, { users: currentUser.id });
      }
    }

    if ((!this.props.projectShortName || !this.props.currentUser) && !!projectShortName && !!currentUser) {
      fetchTasksCount(projectShortName, { users: currentUser.id });
    }
  }

  componentWillUnmount() {
    window.removeEventListener(INNER_EVENT, this.handleInnerEvent);
    window.removeEventListener(UPDATE_TASK_COUNT_EVENT, this.updateTaskCount);
    window.removeEventListener('changeProjectGroupCookie', this.changeProjectGroup);
  }

  checkPageNeedWhiteBackground = () => {
    const {
      projectShortName,
      location: { pathname },
    } = this.props;
    if (Boolean(['/projects', '/', '/project-reports'].find(targetPathname => pathname === targetPathname)))
      return false;
    if (!projectShortName) return true;
    if (pathname.includes('/chatwidgetEditor')) return true;
    return Boolean(['/projects', '/'].find(targetPathname => pathname === targetPathname));
  };

  checkIsProjectPage = () => {
    const { pathname } = this.props.location;
    return Boolean(['/projects', '/'].find(targetPathname => pathname === targetPathname) || pathname.match(/\/{2,}/));
  };

  updateTaskCount = () => {
    const { projectShortName, currentUser, fetchTasksCount } = this.props;
    if (projectShortName && currentUser?.id) {
      fetchTasksCount(projectShortName, { users: currentUser.id });
    }
  };

  hasGithubTokenWarning = () => this.props.projectList?.some(hasGithubTokenWarning);

  changeProjectGroup = async () => {
    const { loginActions, getProjectsList } = this.props;
    await loginActions.login();
    if (isAccess(['SPECIFIC_ACCOUNT_MANAGEMENT']) || isAccess(['ACCOUNTS_TARIFF_READ'])) {
      this.getAccountData(this.props.currentUser.account.id);
    }
    getProjectsList(this.getProjectsListInner);
    history.push('/');
  };

  render() {
    const {
      currentUser,
      adminUser,
      alerts,
      dismissAllAlerts,
      countryIsoCode,
      addMessage,
      cloudDomains,
      ccSessionsAreEnabled,
      universalLoginMenuEnabled,
    } = this.props;
    const { projectShortName } = this.context;
    const isLoggedInOrNotInternal = !isInternal() || isLoggedInAccount();
    const isPageWithWhiteBackground = this.checkPageNeedWhiteBackground();
    const isProjectsPage = this.checkIsProjectPage();

    if (!this.state.load) return <Spinner />;
    return (
      <TestWidgetContextProvider language={currentUser?.language}>
        <GlobalRightPanelContextProvider>
          <div className='app'>
            <HeaderArea
              {...this.props}
              currentUser={currentUser}
              adminUser={adminUser || currentUser}
              isLoggedInOrNotInternal={isLoggedInOrNotInternal}
              showGithubWarning={this.state.showGithubWarning}
              countryIsoCode={countryIsoCode}
              addMessage={addMessage}
              cloudDomains={cloudDomains}
              ccSessionsAreEnable={ccSessionsAreEnabled}
              universalLoginMenuEnabled={universalLoginMenuEnabled}
            />

            {isLoggedInOrNotInternal && !isProjectsPage && <Sidebar {...this.props} />}

            <main className='main'>
              <GlobalAlerts
                alerts={alerts}
                dismissAllAlerts={dismissAllAlerts}
                locale={currentUser?.language === 'RU' ? 'ru' : 'en'}
              />

              <div
                className={classNames('main-container', 'container-fluid', 'just-ui-custom-scrollbar', {
                  'has-white-background': isPageWithWhiteBackground,
                })}
                id='scrollableContainer'
              >
                {this.props.children}
              </div>

              {isLoggedInOrNotInternal && projectShortName && isAccess('GENERAL_ANALYTICS_READ') && (
                <GlobalBottomPanel location={this.props.location} />
              )}
            </main>

            {isLoggedInOrNotInternal && <GlobalRightPanel />}
            <ProjectGroupLeaveModal userId={currentUser?.id} />
          </div>
        </GlobalRightPanelContextProvider>
      </TestWidgetContextProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.CurrentUserReducer.currentUser,
    adminUser: state.CurrentUserReducer.adminUser?.currentUser,
    language: state.CurrentUserReducer.language,
    projectShortName: state.CurrentProjectsReducer.currentProject,
    totalTasksElements: state.TasksReducer.totalTasksElements,
    features: state.CurrentUserReducer.features,

    alerts: state.GlobalAlert.alerts,

    loggedInAccount: state.AccountsReducer.loggedInAccount,
    accounts: state.AccountsReducer.accounts,
    strDialogsSearch: state.AnalyticsFilterReducer.strDialogsSearch,
    strClientsSearch: state.AnalyticsFilterReducer.strClientsSearch,
    appStatus: state.AppConfigReducer.appStatus,
    appConfig: {
      fetched: state.AppConfigReducer.fetched,
      botadmin: state.AppConfigReducer.botadmin,
    },
    tariffData: state.AccountManagerReducer,
    tariffLimitsStatus: state.CurrentUserReducer.tariffLimitsStatus,
    projectList: state.ProjectsReducer.projectList,
    channelList: state.ChannelsReducer.channelList,
    countryIsoCode: state.AccountManagerReducer.countryIsoCode,
    cloudDomains: state.AppConfigReducer.cloudDomains,
    ccSessionsAreEnabled: state.AppConfigReducer.ccSessionsAreEnabled,
    universalLoginMenuEnabled: state.AppConfigReducer.universalLoginMenuEnabled,
    userNeedToSpecifyCountryIsoCode: state.AppConfigReducer.userNeedToSpecifyCountryIsoCode,
  };
}

const mapDispatchToProps = dispatch => ({
  getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
  fetchTasksCount: bindActionCreators(getTotalUserTasksCount, dispatch),
  dismissMessage: bindActionCreators(dismissMessage, dispatch),
  dismissAllAlerts: bindActionCreators(dismissAllAlerts, dispatch),
  addMessage: bindActionCreators(addMessage, dispatch),
  logoutFromAccount: bindActionCreators(logoutFromAccount, dispatch),
  setCurrentLoginAccount: bindActionCreators(setCurrentLoginAccount, dispatch),
  getCurrentLoginAccount: bindActionCreators(getCurrentLoginAccount, dispatch),
  clearCurrentProject: bindActionCreators(unsetCurrentProject, dispatch),
  getCurrentProject: bindActionCreators(getCurrentProject, dispatch),
  getProjectsList: bindActionCreators(loadProjects, dispatch),
  loginToAccount: bindActionCreators(loginToAccount, dispatch),
  loginActions: bindActionCreators(loginActions, dispatch),
  fetchUserData: bindActionCreators(fetchUserData, dispatch),
  setChannelToPublish: bindActionCreators(setChannelToPublish, dispatch),
  publishChannel: bindActionCreators(publishChannel, dispatch),
  getAppStatus: bindActionCreators(getAppStatus, dispatch),
});
export default withAmplitude()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Full)));
