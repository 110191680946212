import { Modal } from '@just-ai/just-ui';
import { t } from 'localization';
import React, { FC, useCallback } from 'react';
import { JGToolbarIconButton } from '../JGToolbar';
import { useToggle } from '@just-ai/just-ui/dist/utils';

export const ModalCreatePng: FC<{ StageIDInner: string; createPng: () => unknown; className: string }> = React.memo(
  ({ StageIDInner, createPng, className }) => {
    const [isOpen, setOpen, setClose] = useToggle(false);

    const actionClick = useCallback(() => {
      createPng();
      setClose();
    }, [createPng, setClose]);

    return (
      <>
        <Modal
          isOpen={isOpen}
          title={t('ModalCreatePng:title')}
          buttonCancelText={t('Cancel')}
          buttonSubmitText={t('ModalCreatePng:actionButtonText')}
          onCancelClick={setClose}
          onActionClick={actionClick}
        >
          {t('ModalCreatePng:text')}
        </Modal>
        <JGToolbarIconButton
          className={className}
          iconName='farArrowToBottom'
          onClick={setOpen}
          data-test-id='JGToolbar:Export'
          id={`${StageIDInner}_Export`}
          tooltip={t('JGToolbar:Export')}
          placement='bottom'
        />
      </>
    );
  }
);

ModalCreatePng.displayName = 'ModalCreatePng';
