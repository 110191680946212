import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { StageObservablesContextType, useStageObservableContext } from '../../contexts/StageObservablesProvider';
import { findScreenByPath, getValidKonvaName } from '../../../../reducers/JGraph.reducer/Graph';
import { useAppSelector } from '../../../../storeHooks';

import { t } from 'localization';
import { Subject } from 'rxjs';

export const goUpSubject$ = new Subject();

function getPathTestId(pathItems: string[], index: number) {
  const baseId = `JGraph.Breadcrumbs.${pathItems.slice(0, index + 1).join('/') || '/'}.${index}`;
  const isLast = pathItems.length - 1 === index ? '.last' : '';
  return `${baseId}${isLast}`;
}

const PathItem: FC<{
  pathItems: string[];
  currentIndex: number;
  setSelectedGroup: StageObservablesContextType['setSelectedGroup'];
}> = ({ pathItems, currentIndex, setSelectedGroup }) => {
  const pathItem = pathItems[currentIndex];
  const setGroupPath = useCallback(() => {
    if (pathItem === '') {
      setSelectedGroup({
        path: undefined,
        pathId: undefined,
      });
    } else {
      let path: string[] = [];
      for (let i = 0; i <= currentIndex; i += 1) {
        path.push(pathItems[i]);
      }
      let constructPath = path.join('/');
      setSelectedGroup({
        path: constructPath,
        pathId: getValidKonvaName(constructPath),
      });
    }
  }, [currentIndex, pathItem, pathItems, setSelectedGroup]);

  useEffect(() => {
    const sub = goUpSubject$.subscribe(() => {
      if (currentIndex === pathItems.length - 2) {
        setGroupPath();
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, [currentIndex, pathItems.length, setGroupPath]);

  return (
    <>
      <span
        data-test-id={getPathTestId(pathItems, currentIndex)}
        onClick={setGroupPath}
        className='btn btn-link'
        key={pathItem === '' ? t('CurrentGroupPath:Main_Scenario') : `${pathItem}${currentIndex}`}
      >
        {pathItem || t('CurrentGroupPath:Main_Scenario')}
      </span>
      <span>/</span>
    </>
  );
};

export const CurrentGroupPath: FC = React.memo(() => {
  const { screens } = useAppSelector(state => ({
    screens: state.JGraphReducer.graph.blocks,
  }));
  const { selectedGroupPath, setSelectedGroup } = useStageObservableContext();
  const prevReduceValue = useRef<string[]>([]);
  const bound = document.getElementById('JGraphBoundingPlace');

  const pathItems = useMemo(() => {
    return (selectedGroupPath || '').split('/').reduce((acc, curr, index, array) => {
      let value = curr;
      if (value) {
        if (prevReduceValue.current.length > 0) {
          value = [...prevReduceValue.current, curr].join('/');
        }
        const target = findScreenByPath([...acc, value].join('/'), screens);
        if (target) {
          prevReduceValue.current = [];
          acc.push(value);
        } else {
          prevReduceValue.current.push(curr);
          if (index === array.length - 1) {
            acc.push(value);
          }
        }
      } else {
        acc.push(curr);
      }

      return acc;
    }, [] as string[]);
  }, [screens, selectedGroupPath]);
  prevReduceValue.current = [];

  if (!bound) return null;

  if (!selectedGroupPath)
    return createPortal(
      <span data-test-id={getPathTestId(['/'], 0)}>{t('CurrentGroupPath:Main_Scenario')}</span>,
      bound
    );

  return createPortal(
    <div className='JGraphPath'>
      {pathItems.map((pathItem, index) => {
        if (index < pathItems.length - 1) {
          return (
            <PathItem key={pathItem} pathItems={pathItems} currentIndex={index} setSelectedGroup={setSelectedGroup} />
          );
        }
        return (
          <span data-test-id={getPathTestId(pathItems, pathItems.length - 1)} key={pathItem}>
            {pathItem}
          </span>
        );
      })}
    </div>,
    bound
  );
});
