import React, { FC, useMemo } from 'react';
import { JustSelect, OptionType } from '@just-ai/just-ui';

import localization from 'localization';

import { withController } from './Controller';
import { CommonFieldProps } from './types';
import { CommonLabel } from './CommonLabel';

const SelectField: FC<CommonFieldProps> = ({
  name,
  label,
  options,
  invalid,
  isRequired,
  value,
  onChange,
  description,
}) => {
  const selectOptions = useMemo<OptionType[]>(() => {
    return (
      options?.map(el => ({
        value: el.value,
        label: el.localization?.[localization.getLocale()] || el.value,
      })) || []
    );
  }, [options]);

  return (
    <div className='form-group form-group-fix'>
      <CommonLabel name={name} label={label} description={description} isRequired={isRequired} />
      <JustSelect
        value={value}
        options={selectOptions}
        onChange={el => onChange(el?.[0])}
        invalid={invalid}
        position='fixed'
      />
    </div>
  );
};

export const SelectCustomTagField = withController(SelectField);
