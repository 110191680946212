import React, { useCallback } from 'react';
import { Nav, NavItem } from 'reactstrap';

import { isAccountUser, isEuroInstance, isLoggedInAccount, isSAdmin, isSystemAccess } from '../../isAccessFunction';
import localize from '../../localization';
import { useHistory } from 'react-router';
import { cloudDomainsService } from '../../services/CloudDomainsService';

import NotificationBell from './components/NotificationBell';

import './SadminPanel.scss';

const { translate } = localize;

export const SadminPanel = ({ adminUser: currentUser, appConfig, logoutFromAccount, clearCurrentProject }) => {
  const history = useHistory();

  const logoutFromAll = useCallback(() => {
    history.push('/logout');
  }, [history]);

  const goToPageWithAccLogout = useCallback(
    pageURL => () => {
      if (isLoggedInAccount()) {
        if (currentUser.ccSession) {
          return (window.location.href = cloudDomainsService.getSadminLogoutPath(
            `${window.location.origin}${pageURL}`
          ));
        }

        logoutFromAccount().then(() => {
          history.push(pageURL);
          clearCurrentProject();
        });
      } else {
        history.push(pageURL);
      }
    },
    [logoutFromAccount, clearCurrentProject, history, currentUser]
  );

  const accountManagerLinks = [
    {
      testId: 'sadminPanel.status',
      link: '/system/status',
      text: translate('Status'),
    },
  ];

  const linksArray = [
    isSystemAccess(['botadmin_partner_feature']) && {
      testId: 'sadminPanel.partnerPayments',
      link: '/partner_payments',
      text: translate('Partner payments'),
    },
    {
      testId: 'sadminPanel.status',
      link: '/system/status',
      text: translate('Status'),
    },
    {
      testId: 'sadminPanel.licenses',
      link: '/keysandplans',
      text: translate('Plans and Keys'),
    },
  ];

  return (
    <div className='sadmin-header navbar' data-test-id='sadminPanel'>
      <a className='navbar-brand' href='/'>
        {Boolean(appConfig.botadmin.productName) && appConfig.botadmin.productName.length > 0
          ? appConfig.botadmin.productName
          : isEuroInstance()
          ? 'Tovie Platform'
          : 'JAICP'}
      </a>
      <Nav>
        <NavItem data-test-id='sadminPanel.ccRedirect'>
          <a className='nav-link' href={`${cloudDomainsService.getFullDomainPath('cc')}/c/accounts`}>
            {translate('SadminPanel:Button:CC')}
          </a>
        </NavItem>
      </Nav>
      <Nav>
        {(isSAdmin() || isAccountUser() ? linksArray : accountManagerLinks).map(
          link =>
            link && (
              <NavItem data-test-id={link.testId} key={link.testId}>
                <a href='#!' className='nav-link' onClick={goToPageWithAccLogout(link.link)}>
                  {link.text}
                </a>
              </NavItem>
            )
        )}
      </Nav>
      {Boolean(currentUser) && (
        <Nav>
          {isSystemAccess('account_billing_reports') && (
            <NavItem>
              <NotificationBell />
            </NavItem>
          )}
          <NavItem>
            <span className='nav-link'>{currentUser.email}</span>
          </NavItem>
          <NavItem>
            <a className='logout-link' data-test-id='sadminPanel.logoutLink' href='#!' onClick={logoutFromAll}>
              {localize.translate('Log out')}
            </a>
          </NavItem>
        </Nav>
      )}
    </div>
  );
};

export default SadminPanel;
