export const tarifflimitannouncementLocalization = {
  'TariffLimitAnnouncement demoPeriodWillEndSoon': {
    eng: 'The demo period is expiring in $[1], and the bots will stop working. You might want to pay for your plan in advance',
    ru: 'Через $[1] завершится демо-период и боты перестанут работать. Рекомендуем оплатить тариф заранее',
    cn: '演示期会在 $[1]结束，机器人也将停止工作。您可能想提前支付',
  },
  'TariffLimitAnnouncement payedPeriodWillEndSoon': {
    eng: 'The paid period is expiring in $[1]. You should pay for your plan in advance to keep your bots from stopping',
    ru: 'Через $[1] завершится оплаченный период. Чтобы боты не остановились оплатите тариф заранее',
    cn: '付费期会在 $[1] 结束 。您应该提前支付以防机器人停止工作',
  },
  'TariffLimitAnnouncement uniqueWillEndSoon': {
    eng: 'You have less than 20% unique users left. Please buy an additional package',
    ru: 'Осталось менее 20% уникальных пользователей. Подключите дополнительный пакет',
    cn: '您有小于20%的单个用户量。请购买额外套餐包',
  },
  'TariffLimitAnnouncement nluMinutesWillEndSoon': {
    eng: 'You have less than 20% of NLU minutes in your plan. Please buy an additional package',
    ru: 'Осталось менее 20% NLU-минут от тарифа. Подключите дополнительный пакет',
    cn: '您的套餐剩余不足20%自然语言理解分钟。请购买额外套餐包',
  },
  'TariffLimitAnnouncement telephonyMinutesWillEndSoon': {
    eng: 'You have less than 20% of Just AI telephony minutes. Please buy an additional package',
    ru: 'Осталось менее 20% минут телефонии JustAI. Подключите дополнительный пакет',
    cn: '您的Just AI电话分钟不足20%。请购买额外套餐包',
  },
  'TariffLimitAnnouncement asrMinutesWillEndSoon': {
    eng: 'You have less than 20% of Just AI ASR/TTS minutes. Please buy an additional package',
    ru: 'Осталось менее 20% минут ASR/TTS JustAI. Подключите дополнительный пакет',
    cn: '您的Just AI 语音识别、语音合成分钟不足20%。请购买额外套餐包',
  },
  'TariffLimitAnnouncement cailaDirectApiCallsWillEndSoon': {
    eng: 'You have less than 20% of queries to NLP Direct API left. Please buy an additional package',
    ru: 'Осталось менее 20% запросов к NLP Direct API. Подключите дополнительный пакет',
    cn: '您的CAILA接口调用量不足20%。请购买额外套餐包',
  },
  'TariffLimitAnnouncement demoPeriodFinishedWithoutPay': {
    eng: 'The demo period is over. Communication with users is suspended. Please buy a plan to have your bots enabled.',
    ru: 'Завершился демо-период. Пользователи не обслуживаются. Для включения ботов оплатите тариф',
    cn: '演示期结束。与用户通话暂停。请购买计划启动机器人',
  },
  'TariffLimitAnnouncement payedPeriodFinishedWithoutPay': {
    eng: 'The plan has not been paid for. Communication with users is suspended. Please buy a plan to have your bots enabled.',
    ru: 'Тариф не оплачен. Пользователи не обслуживаются. Для включения ботов оплатите тариф',
    cn: '套餐还未付费。与用户通话暂停。请购买套餐启动机器人',
  },
  'TariffLimitAnnouncement uniqueExceed': {
    eng: 'The unique user limit has been exceeded. Please buy an additional package',
    ru: 'Превышен лимит уникальных пользователей. Подключите дополнительный пакет',
    cn: '已超出单个用户限额。请购买额外套餐',
  },
  'TariffLimitAnnouncement nluMinutesExceed': {
    eng: 'You are out of NLU minutes. Bots deployed to phone channels have been suspended. Please buy an additional package',
    ru: 'Израсходованы все минуты NLU. Боты в телефонных каналах не работают. Подключите дополнительный пакет',
    cn: '您的自然语言理解分钟已用完。机器人电话通道已暂停。请购买额外套餐 ',
  },
  'TariffLimitAnnouncement telephonyMinutesExceed': {
    eng: 'You are out of Just AI telephony minutes. Bots deployed to phone channels have been suspended. Please buy an additional package',
    ru: 'Израсходованы все минуты телефонии Just AI. Боты в телефонных каналах не работают. Подключите дополнительный пакет',
    cn: '您的Just AI电话分钟量已用完。机器人电话通道已暂停。请购买额外套餐',
  },
  'TariffLimitAnnouncement asrMinutesExceed': {
    eng: 'No Just AI ASR/TTS minutes Set up <a href="#{config.botadmin.helpUrl}/telephony/recognition_synthesis_settings" target="_blank" rel="noreferrer noopener">your own ASR/TTS connection</a> or contact your manager',
    ru: 'Отсутствуют минуты ASR/TTS Just AI. Настройте <a href="#{config.botadmin.helpUrl}/telephony/recognition_synthesis_settings" target="_blank" rel="noreferrer noopener">собственное подключение ASR/TTS</a> или обратитесь к вашему менеджеру',
  },
  'TariffLimitAnnouncement cailaDirectApiCallsExceed': {
    eng: 'NLU provider query count exceeded. NLP Direct API usage is restricted. Please buy an additional package',
    ru: 'Превышены запросы к NLU-провайдеру. Использование NLP Direct API ограничено. Подключите дополнительный пакет',
    cn: '已超出自然语言理解服务查询限额。NLP Direct API接口调用受到限制。请购买额外套餐',
  },
  'TariffLimitAnnouncement in': {
    eng: 'in',
    ru: 'в',
  },
  'TariffLimitAnnouncement btn text request': {
    eng: 'Contact manager',
    ru: 'Связаться с менеджером',
    cn: '联系管理员',
  },
  'TariffLimitAnnouncement btn text buy': {
    eng: 'Buy',
    ru: 'Купить',
  },
  'TariffLimitAnnouncement asrMinutesExceed_developer': {
    eng: 'You are out of Just AI ASR/TTS minutes. Bots deployed to phone channels have been suspended. Please buy an additional package',
    ru: 'Израсходованы все минуты ASR/TTS Just AI. Боты в телефонных каналах не работают. Подключите дополнительный пакет',
  },
  'TariffLimitAnnouncement:BE-error accountsadmin.user.email.not.filled': {
    eng: 'To be able to buy additional packages, fill out your email in the profile settings',
    ru: 'Чтобы покупать дополнительные пакеты, заполните email в личном кабинете',
  },
};
