import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useToggle } from '../utils';
import './AudioPlayer.scss';
import { BackgroundType, DropdownButton, DropdownToggle, Icon, IconButton, Spinner } from '../index';
import { ProgressBar } from './ProgressBar';
import { VolumeControls } from './VolumeBar';
import classNames from 'classnames';
import SpeedAudio from './SpeedAudio';
const getSecondsToMinutesAndSeconds = (time) => {
    if (time === 0) {
        return '0:00';
    }
    const minutes = Math.floor(time / 60);
    const seconds = String(time - minutes * 60);
    return `${minutes}:${seconds.length === 1 ? '0' + seconds : seconds}`;
};
export const AudioPlayer = ({ url, children, autoPlay = false, DropdownMenu, histogram, histogramId, onChangeDuration = () => { }, currentSpeed, setCurrentSpeed, volumeAlignment = 'vertical', dataTestId, onPlay, onPause, CustomButton, fetching, checkUrlOnInit = true, }) => {
    const audioPlayerRef = useRef(null);
    const [currentTrackDuration, setCurrentTrackDuration] = useState(0);
    const [currentTrackMoment, setCurrentTrackMoment] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isOpenedVolume, , closeVolume, toggleVolume] = useToggle(false);
    const volumeContainerRef = useRef(null);
    const volumeButtonRef = useRef(null);
    const [hasError, setHasError, setHasNoError] = useToggle(false);
    const [wasInit, setWasInit] = useState(checkUrlOnInit);
    const checkClickTarget = useCallback((e) => {
        var _a, _b;
        if (!e.target) {
            return;
        }
        const isButtonClicked = (_a = volumeButtonRef === null || volumeButtonRef === void 0 ? void 0 : volumeButtonRef.current) === null || _a === void 0 ? void 0 : _a.contains(e.target);
        if (!isButtonClicked && !((_b = volumeContainerRef === null || volumeContainerRef === void 0 ? void 0 : volumeContainerRef.current) === null || _b === void 0 ? void 0 : _b.contains(e.target))) {
            closeVolume();
        }
    }, [volumeButtonRef, closeVolume]);
    useEffect(() => {
        document.addEventListener('click', checkClickTarget);
        return () => document.removeEventListener('click', checkClickTarget);
    }, [isOpenedVolume, checkClickTarget]);
    const handlePlay = useCallback(e => {
        setWasInit(true);
        e.preventDefault();
        if (audioPlayerRef.current) {
            if (audioPlayerRef.current.paused || audioPlayerRef.current.ended) {
                audioPlayerRef.current.play();
                setIsPlaying(true);
            }
            else {
                audioPlayerRef.current.pause();
                setIsPlaying(false);
            }
        }
    }, [audioPlayerRef.current]);
    const handleMetadata = useCallback(() => {
        const duration = Math.floor(audioPlayerRef.current ? audioPlayerRef.current.duration : 0);
        setCurrentTrackDuration(duration);
    }, [audioPlayerRef]);
    const handleTimeupdate = useCallback(event => {
        if (audioPlayerRef.current) {
            setCurrentTrackMoment(Math.floor(audioPlayerRef.current.currentTime));
        }
    }, [audioPlayerRef]);
    const handlePlayend = useCallback(() => {
        if (audioPlayerRef && audioPlayerRef.current)
            audioPlayerRef.current.currentTime = 0;
        setCurrentTrackMoment(0);
        setIsPlaying(false);
    }, [audioPlayerRef]);
    const handleChangeSpeed = useCallback((newSpeed) => {
        if (currentSpeed && setCurrentSpeed) {
            localStorage.AUDIO_PLAYBACK_RATE = newSpeed;
            setCurrentSpeed(newSpeed);
        }
    }, [currentSpeed, setCurrentSpeed]);
    useEffect(() => {
        onChangeDuration(currentTrackDuration);
    }, [currentTrackDuration, onChangeDuration]);
    useEffect(() => {
        const audio = audioPlayerRef.current;
        if (audio && typeof currentSpeed === 'number') {
            audio.playbackRate = currentSpeed;
        }
    }, [currentSpeed, url]);
    const iconPlayName = isPlaying ? 'faPause' : 'faPlay';
    return (React.createElement("div", { className: classNames('justui-audio-player', { hasError: hasError && wasInit }) },
        React.createElement("audio", { src: url, controls: false, autoPlay: autoPlay, preload: 'metadata', onLoadedMetadata: handleMetadata, onTimeUpdate: e => handleTimeupdate(e), ref: audioPlayerRef, onEnded: handlePlayend, onError: setHasError, onCanPlay: setHasNoError, onPlay: e => {
                //for autoPlay
                setIsPlaying(true);
                onPlay && onPlay(e);
            }, onPause: onPause ? onPause : undefined, "data-test-id": 'justui-audio-player__audio-tag', className: 'audio' }, children),
        React.createElement("div", { className: 'justui-audio-player__container' },
            React.createElement("div", { className: classNames({ 'justui-audio-player__icon': fetching }) }, fetching ? (React.createElement("div", { className: 'justui-audio-player__spinner-wrapper' },
                React.createElement(Spinner, { style: { width: '12px', height: '12px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }, backgroundType: BackgroundType.without }))) : (React.createElement(IconButton, { "data-test-id": (dataTestId === null || dataTestId === void 0 ? void 0 : dataTestId.playButton) || 'justui-audio-player__custom-button', color: 'primary', className: 'justui-audio-player__custom-button', name: iconPlayName, onClick: handlePlay, sharp: true, flat: true, disabled: url === '' }))),
            React.createElement("span", { className: 'justui-audio-player__duration', "data-test-id": 'justui-audio-player__current_moment' }, getSecondsToMinutesAndSeconds(currentTrackMoment)),
            React.createElement(ProgressBar, { audio: audioPlayerRef.current, progressUpdateInterval: 20, ShowFilledProgress: true, histogram: histogram, histogramId: histogramId }),
            React.createElement("span", { className: 'justui-audio-player__duration', "data-test-id": 'justui-audio-player__duration' }, getSecondsToMinutesAndSeconds(currentTrackDuration)),
            React.createElement("div", { className: 'justui-audio-player__vol-container', "data-test-id": (dataTestId === null || dataTestId === void 0 ? void 0 : dataTestId.volumeButton) || 'justui-audio-player__vol-container' },
                currentSpeed && setCurrentSpeed && (React.createElement(SpeedAudio, { onChangeSpeed: handleChangeSpeed, currentSpeed: currentSpeed })),
                React.createElement("div", { style: { position: 'relative' } },
                    React.createElement("div", { className: classNames('volume-container', {
                            hidden: !isOpenedVolume,
                            horizontal: volumeAlignment === 'horizontal',
                        }), ref: volumeContainerRef },
                        React.createElement(VolumeControls, { volume: 1, audio: audioPlayerRef.current, alignment: volumeAlignment })),
                    React.createElement(IconButton, { name: 'faVolumeUp', className: classNames('justui-audio-player__custom-button justui-audio-player__custom-button_volume'), onClick: toggleVolume, sharp: true, flat: true, innerRef: volumeButtonRef, "data-test-id": 'justui-audio-player__custom-button' })),
                DropdownMenu && (React.createElement(DropdownButton, { direction: 'down', "data-test-id": (dataTestId === null || dataTestId === void 0 ? void 0 : dataTestId.menuButton) || 'AudioPlayer.dropdownMenu' },
                    React.createElement(DropdownToggle, { className: 'justui-audio-player__custom-button justui-audio-player__custom-button_menu justui_button btn-flat square btn' },
                        React.createElement(Icon, { name: 'faEllipsisV', className: 'btn-icon' })),
                    React.createElement(DropdownMenu, null))),
                CustomButton && React.createElement(CustomButton, null)))));
};
