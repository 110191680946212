var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { TestSuiteApi, TestSuiteRunApi, TestSuiteTemplateApi, } from "../../generated/BotScorer";
export default class BotScorerApiService {
    constructor(accountId, projectShortName, axios) {
        this.accountId = accountId;
        this.projectShortName = projectShortName;
        this.testSuiteApi = new TestSuiteApi({}, BotScorerApiService.BASE_PATH, axios);
        this.testSuiteRunApi = new TestSuiteRunApi({}, BotScorerApiService.BASE_PATH, axios);
        this.testSuiteTemplateApi = new TestSuiteTemplateApi({}, BotScorerApiService.BASE_PATH, axios);
    }
    addDynamicStatsInTestSuite(testSuite) {
        let lastSuccessRate = null;
        const totalRunTests = testSuite.testCount - testSuite.testSkipCount;
        for (let run of testSuite.testSuiteResults) {
            if (run.successTestCount + run.failedTestCount === 0)
                continue;
            if (["ERROR", "DEPLOY_FAILED"].includes(run.status.type))
                continue;
            const successRate = run.successTestCount
                ? run.successTestCount / totalRunTests
                : 0;
            run.successRate = successRate;
            if (lastSuccessRate === null) {
                lastSuccessRate = successRate;
                continue;
            }
            run.successRateDiff = successRate - lastSuccessRate;
            lastSuccessRate = successRate;
        }
        return testSuite;
    }
    getTestSuite() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.testSuiteApi.getTestSuite(this.accountId, this.projectShortName);
            return res.data.map((testSuite) => this.addDynamicStatsInTestSuite(testSuite));
        });
    }
    deleteTestSuite(checksum) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.testSuiteApi.deleteTestSuite(this.accountId, this.projectShortName, checksum);
            return res.data;
        });
    }
    createTestSuite(name, file) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.testSuiteApi.createTestSuite(this.accountId, this.projectShortName, name, file);
            return res.data;
        });
    }
    renameTestSuite(checksum, name) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.testSuiteApi.renameTestSuite(this.accountId, this.projectShortName, checksum, name);
            return res.data;
        });
    }
    runTestSuite(checkSum) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.testSuiteRunApi.runTestSuite(this.accountId, this.projectShortName, checkSum);
            return res.data;
        });
    }
    getTestSuiteFile(checkSum) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.testSuiteApi.getTestSuiteFile(this.accountId, this.projectShortName, checkSum);
            return res.data;
        });
    }
    getTestRunReportLink(id) {
        return `/api/bot-scorer/account/${this.accountId}/project/${this.projectShortName}/test-run/${id}/export`;
    }
    getDownloadTemplateLink() {
        return `/api/bot-scorer/account/${this.accountId}/downloadTemplate`;
    }
}
BotScorerApiService.BASE_PATH = "";
