import React, { FC, ReactElement } from 'react';
import { FormFeedback, InputText, TextAreaLikeElement } from '@just-ai/just-ui';

import { t } from 'localization';

import { CommonFieldProps } from './types';
import { withController } from './Controller';
import { CommonLabel } from './CommonLabel';
import { FieldProps } from './index';

interface StringFieldProps extends CommonFieldProps {
  multiline?: boolean;
  multilineRowsMin?: number;
  multilineRowsMax?: number;
  RightSideLabelElement?: ReactElement;
}

interface StringFieldViewProps extends Omit<StringFieldProps, 'type'> {
  type?: 'text' | 'number';
}

const StringFieldView: FC<StringFieldViewProps> = ({
  name,
  label,
  isRequired,
  value,
  onChange,
  onBlur,
  invalid,
  type,
  description,
  multiline = false,
  multilineRowsMin = 2,
  multilineRowsMax = 5,
  RightSideLabelElement,
}) => {
  return (
    <div className='form-group form-group-fix overflow-hidden'>
      <CommonLabel
        name={name}
        label={label}
        description={description}
        isRequired={isRequired}
        RightSideElement={RightSideLabelElement}
      />
      {multiline ? (
        <TextAreaLikeElement
          id='text'
          value={value}
          onChange={onChange}
          minRows={multilineRowsMin}
          maxRows={multilineRowsMax}
        />
      ) : (
        <InputText name={name} onChange={onChange} value={value} invalid={invalid} onBlur={onBlur} type={type} />
      )}

      {isRequired && (
        <FormFeedback tag='div' valid={false}>
          {t('Required Field')}
        </FormFeedback>
      )}
    </div>
  );
};

export const StringField = withController(StringFieldView);

export const StringFieldViewForCustomTag: FC<FieldProps> = props => {
  const inputType = props.type === 'integer' ? 'number' : 'text';
  return <StringField {...props} type={inputType} />;
};
