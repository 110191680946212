import { Modal } from '@just-ai/just-ui';
import React, { FC, useCallback } from 'react';
import localize, { tWithCheck } from 'localization';
import { t } from 'localization';
import { jgloaderrorLocalization } from './jgloaderror.loc';
import { useAppContext } from '../../../Caila/components/AppContext';
import history from '../../../../appHistory';

localize.addTranslations(jgloaderrorLocalization);

export const JGraphLoadError: FC<{ error: string }> = ({ error }) => {
  const { projectShortName } = useAppContext();
  const returnToCode = useCallback(() => {
    history.push(`/${projectShortName}/editor`);
  }, [projectShortName]);

  return (
    <Modal
      isOpen={true}
      title={t(`LoadJGraphErrorModal:title`)}
      buttonCancelColor='secondary'
      buttonCancelOutline
      buttonCancelTestId='LoadJGraphErrorModal.GoToTextEditor'
      buttonCancelText={t('LoadJGraphErrorModal:Return to code')}
      data-test-id='LoadJGraphErrorModal'
      titleTestId='LoadJGraphErrorModal.Title'
      onCancelClick={returnToCode}
    >
      <p>{tWithCheck(`LoadJGraphErrorModal:${error}`) || error}</p>
    </Modal>
  );
};
