import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import Konva from 'konva';
import cn from 'classnames';

import { Vector2d } from 'konva/lib/types';
import { KonvaEventObject } from 'konva/lib/Node';

import { amplitudeInstance } from 'pipes/functions';
import { useAppDispatch, useAppSelector } from 'storeHooks';

import { getConnectionsFromBlocks } from 'reducers/JGraph.reducer/Graph';
import { EditMenuBlock, JStateWithId } from 'reducers/JGraph.reducer/types';
import {
  makeNewConnectorAsync,
  onStageClickAsync,
  updateJGraphVisuals,
} from 'reducers/JGraph.reducer/JGraphAsyncActions';
import {
  JGLS,
  openContextMenu,
  openScreenCreationMenu,
  setEditMenuBlock,
  showStateNameEditField,
  toggleAddingActionsMenu,
  closeScreenCreationMenu,
} from 'reducers/JGraph.reducer';
import LabelingToolMenu, { LabelingToolMenu$ } from 'modules/JGraph/view/LabelingToolMenu';

import { useStageObservableContext } from '../contexts/StageObservablesProvider';
import { ConnectorLayer } from './ConnectorLayer';
import { StateScreen } from './StateScreen';
import {
  findParentScreenName,
  initialNewConnectorState,
  lazyActionsSubject,
  newConnectorPipe$,
  newConnectorSubject$,
  useStageSizes,
} from '../hooks';
import {
  getCenterOfStage,
  getInnerScreensPathIdMap,
  getStageFromEvent,
  setScale,
  StageActions,
} from '../utils/stageUtils';
import { updateUsingStageProps } from '../utils/connectionLayerUtils';

import { FloatingConnectorMenu, FloatingConnectorMenu$ } from './FloatingConnectorMenu';
import { mainSave$, useSavingPipe } from '../hooks/savingPipe';
import { IncomingOutgoingConnections } from './IncomingOutgoingConnections';
import ActionLayer from './Layers/ActionLayer';
import { getMinMaxScreensCoords } from '../utils/common';
import { JGToolbar, JGToolbarIconButton } from './JGToolbar';
import SearchState from './SearchState';

import { t } from 'localization';
import JGToolbarClasses from './JGToolbar/JGToolbar.module.scss';
import { JGraphProviderSubject$ } from '../contexts/JGraphContext';
import { ModalCreatePng } from './ModalCreatePng';
import { CommitButtonWithContext } from './CommitButton';
import { useCopyToClipboardStateKeyboardHandlers } from 'utils/hooks/CopyToClipboardFeature';
import { HighLightConnectorsStatus } from './HighLightConnectorsStatus';

import AutoPlacementButton from './AutoPlacementButton';
import { highLightConnectors$ } from '../hooks/highLightConnectors';
import { StickerLayer } from './StickerLayer';
import { useStickerActions, useStickerCreationMode } from './Sticker/hooks';
import keyboardjs from 'keyboardjs';
import { callIfAbleToApplyHotKey } from 'utils/hotkeys';
import { useStatesStoreRef } from '../hooks/useStatesStore';
import { getIntersection } from '../utils/blockLayerUtils';
import { CollapsedConnectorsMenu, CollapsedConnectorsMenuClose } from './Connector/CollapsedConnectorsMenu';
import { hiddenConnectionsPipe$ } from '../hooks/hiddenConnections';
import { StateCollapseSubjectPipe$ } from './StateScreen.hooks';
import { combineLatest } from 'rxjs';
import { LevelUpButton } from './LevelUpButton';

type StageViewProps = {
  containerRef: RefObject<HTMLDivElement>;
  screens: JStateWithId[];
  showIncomingOutgoingConnections?: boolean;
  parentScreenPath?: string;
  StageID?: string;
};

export const StageView = React.memo(
  ({
    containerRef,
    parentScreenPath = '',
    screens,
    showIncomingOutgoingConnections = false,
    StageID,
  }: StageViewProps) => {
    const observableProps = useStageObservableContext();
    const {
      isEditModeEnable,
      fromStateTransitions,
      isAddingMenuOpen,
      screenCreationMenu,
      contextMenu,
      loadingGraph,
      loadingCustomTags,
      projectShortName,
      stickers,
    } = useAppSelector(state => ({
      isEditModeEnable: state.JGraphReducer.isEditModeEnable,
      projectShortName: state.CurrentProjectsReducer.currentProject,
      fromStateTransitions: state.JGraphReducer.graph.fromStateTransitions,
      isAddingMenuOpen: state.JGraphReducer.isAddingMenuOpen,
      screenCreationMenu: state.JGraphReducer.screenCreationMenu,
      contextMenu: state.JGraphReducer.contextMenu,
      loadingGraph: state.JGraphReducer.loadingGraph,
      loadingCustomTags: state.JGraphReducer.loadingCustomTags,
      stickers: state.JGraphReducer.stickers.filter(sticker => {
        let stickerStagePath = sticker.stagePath;
        const currentStagePath = observableProps.selectedGroupPath ?? '';
        const connectedStatePath = sticker.connection?.statePath;
        if (connectedStatePath) {
          stickerStagePath = connectedStatePath.split('/').slice(0, -1).join('/');
        }
        return stickerStagePath === currentStagePath;
      }),
    }));
    const screenSizesMap = useStatesStoreRef();
    const dispatch = useAppDispatch();
    const { saveMove } = useSavingPipe();

    useCopyToClipboardStateKeyboardHandlers(observableProps.isActive);

    const stage = React.useRef<Konva.Stage | null>(null);
    const blocksLayer = React.useRef<Konva.Layer>(null);
    const splinesLayer = React.useRef<Konva.Layer>(null);
    const actionLayer = React.useRef<Konva.Layer>(null);

    const [stageDidMount, setDidMount] = useState(false);
    useEffect(() => {
      if (stageDidMount) return;
      if (stage.current) {
        setDidMount(true);
      }
    }, [stageDidMount]);

    const size = useStageSizes(containerRef.current);
    useEffect(() => {
      if (!loadingGraph && !loadingCustomTags && stage.current) {
        JGLS.store.getStagePathSettings(observableProps.selectedGroupPathId || '').then(stageSettings => {
          if (!stage.current) return;
          stage.current.position(stageSettings?.stagePosition);
          stage.current.scale({ x: stageSettings.stageScale, y: stageSettings.stageScale });
        });
      }
    }, [loadingGraph, loadingCustomTags, observableProps.selectedGroupPathId]);

    const newConnectorEndRx = useCallback(
      (event: KonvaEventObject<MouseEvent>) => {
        const sub = newConnectorPipe$.subscribe(innerStore => {
          if (!innerStore.mouseUp && innerStore.started) {
            if (innerStore.from) {
              const [targetStateName] = findParentScreenName(event.target);
              if (targetStateName) {
                newConnectorSubject$.next(initialNewConnectorState);
                dispatch(
                  makeNewConnectorAsync({
                    from: innerStore.from,
                    to: targetStateName,
                  })
                );
              } else {
                newConnectorSubject$.next({ mouseUp: true });
                dispatch(
                  openScreenCreationMenu({
                    screenPosition: innerStore.toPosition,
                    pointerPosition: { x: event.evt.pageX, y: event.evt.pageY },
                    from: innerStore.from,
                    transitionTo: innerStore.transitionTo,
                    parentPath: observableProps.selectedGroupPath,
                  })
                );
              }
            }
          }
        });
        sub.unsubscribe();
      },
      [dispatch, observableProps.selectedGroupPath]
    );

    const setScaleAndSave = useCallback(
      (ev: KonvaEventObject<WheelEvent | DragEvent>) => {
        if (isAddingMenuOpen) return;
        LabelingToolMenu$.next(null);
        CollapsedConnectorsMenuClose();
        const [newScale, newPosition] =
          ev.evt.type === 'wheel'
            ? setScale(ev as KonvaEventObject<WheelEvent>)
            : [stage.current!.scaleX(), stage.current!.position()];
        if (ev.evt.type === 'wheel' || !!ev.target.attrs.isStage) {
          const settings = {
            stageScale: newScale,
            stagePosition: newPosition,
          };
          const stageStage = getStageFromEvent(ev);
          observableProps.getStage(stageStage);
          const group = observableProps.selectedGroupPathId || '';
          lazyActionsSubject.next({
            type: 'saveVisualStageFileSettings',
            action: async () => await JGLS.store.saveStagePathSettings(settings, group),
          });
        }
      },
      [isAddingMenuOpen, observableProps]
    );

    const openContextMenuHandler = useCallback(
      (event: Konva.KonvaEventObject<PointerEvent>) => {
        event.cancelBubble = true;
        event.evt.preventDefault();
        event.evt.stopPropagation();

        if (contextMenu.open || !stage.current) return;

        dispatch(closeScreenCreationMenu());

        let screenPosition = stage.current.getPointerPosition() || { x: 0, y: 0 };
        [, screenPosition] = updateUsingStageProps(stage.current, { x: 0, y: 0 }, screenPosition);

        const intersection = getIntersection(screenPosition, screenSizesMap.current, event.currentTarget);
        const pointerPosition = {
          x: event.evt.pageX,
          y: event.evt.pageY,
        };
        dispatch(
          openContextMenu({
            screenPosition,
            pointerPosition,
            statePath: intersection?.statePath,
            statePosition: intersection?.coords,
          })
        );
      },
      [contextMenu.open, dispatch, screenSizesMap]
    );

    const alternateOpenCreationMenu = useCallback(
      event => {
        if (!screenCreationMenu.open) {
          if (stage.current) {
            let screenPosition = getCenterOfStage(stage.current) || { x: 0, y: 0 };
            const targetPosition = event.currentTarget.getBoundingClientRect();
            dispatch(
              openScreenCreationMenu({
                screenPosition: screenPosition,
                pointerPosition: {
                  x: targetPosition.right + 18,
                  y: targetPosition.top + 22,
                },
                parentPath: observableProps.selectedGroupPath,
                isOpenedByButton: true,
              })
            );
          }
        }
      },
      [dispatch, screenCreationMenu.open, observableProps.selectedGroupPath]
    );

    const dropNewConnection = useCallback(isKonvaCreationMenuOpen => {
      isKonvaCreationMenuOpen && newConnectorSubject$.next(initialNewConnectorState);
    }, []);

    const stageActions = useMemo(
      (): StageActions => ({
        toggleAddingActionsMenu: (event: Konva.KonvaEventObject<MouseEvent>, screenId?: string) => {
          event.cancelBubble = true;
          isEditModeEnable && dispatch(toggleAddingActionsMenu({ event, screenId }));
        },
        showStateNameEditField: (event: Konva.KonvaEventObject<MouseEvent>, stateName: string) => {
          event.cancelBubble = true;
          dispatch(showStateNameEditField({ event, stateName }));
        },
        setEditMenuBlock: (editMenuBlock?: EditMenuBlock) =>
          isEditModeEnable && dispatch(setEditMenuBlock(editMenuBlock)),
      }),
      [isEditModeEnable, dispatch]
    );

    const onStageClick = useCallback(
      (event: KonvaEventObject<MouseEvent>) => {
        CollapsedConnectorsMenuClose();
        FloatingConnectorMenu$.next({ connector: undefined });
        const highLightConnectorsValue = highLightConnectors$.getValue();
        if (highLightConnectorsValue.connector) {
          highLightConnectors$.next({ ...highLightConnectorsValue, connector: null });
        }
        if (!isEditModeEnable) return;
        dispatch(onStageClickAsync({ event }));
        dropNewConnection(screenCreationMenu.open);
      },
      [dispatch, dropNewConnection, isEditModeEnable, screenCreationMenu.open]
    );

    const stageGetRef = useCallback(
      Stage => {
        //@ts-ignore
        if (window.Cypress) {
          if (showIncomingOutgoingConnections) {
            //@ts-ignore
            window.jgStageGroup = Stage;
          } else {
            //@ts-ignore
            window.jgStage = Stage;
          }
        }
        observableProps.getStage(Stage);
        stage.current = Stage;
      },
      [observableProps, showIncomingOutgoingConnections]
    );

    const flatScreensPathId = useMemo(() => getInnerScreensPathIdMap(screens), [screens]);

    const showIncomingConnectors = useMemo(() => {
      if (showIncomingOutgoingConnections) {
        let connections = observableProps.getAboveConnectors();
        connections = connections.filter(
          connector => flatScreensPathId.includes(connector.to || '') && !flatScreensPathId.includes(connector.fromNode)
        );
        return connections;
      }
      return [];
    }, [showIncomingOutgoingConnections, observableProps, flatScreensPathId]);

    const showOutgoingConnectors = useMemo(() => {
      if (showIncomingOutgoingConnections) {
        let { connections } = getConnectionsFromBlocks(screens);
        connections = connections.filter(
          connector => !flatScreensPathId.includes(connector.to || '') && !connector.from.includes('fromState')
        );
        //INFO this is for "fromState" connections
        let aboveConnectors = observableProps
          .getAboveConnectors()
          .filter(
            aboveConnection =>
              aboveConnection.from.includes('fromState') &&
              flatScreensPathId.includes(aboveConnection.fromNode) &&
              !aboveConnection.toNodeOriginalPath.startsWith(observableProps.selectedGroupPath!)
          );
        aboveConnectors.forEach(connect => connections.push(connect));
        return connections;
      }
      return [];
    }, [showIncomingOutgoingConnections, screens, observableProps, flatScreensPathId]);

    const initialIncomingOutgoingPositionFilled = useRef<boolean>(false);

    const initialIncomingOutgoingPosition = useMemo<{ incoming: Vector2d; outgoing: Vector2d }>(() => {
      const { minX, minY, maxX } = getMinMaxScreensCoords(screens);
      initialIncomingOutgoingPositionFilled.current = true;
      return {
        incoming: {
          x: minX - 850,
          y: minY,
        },
        outgoing: {
          x: maxX + 850,
          y: minY,
        },
      };
    }, [screens]);

    const StageIDInner = StageID || 'StageID';

    const createPng = useCallback(async () => {
      if (!stage.current) return;
      const MAX_PNG_SIZE = 16380 * 16380;
      let { minX, minY, maxX, maxY } = getMinMaxScreensCoords(screens, stickers);

      if (observableProps.selectedGroupPath) {
        const incoming = stage.current.findOne('#IncomingOutgoing_incoming');
        const outgoing = stage.current.findOne('#IncomingOutgoing_outgoing');
        if (incoming) {
          if (incoming.x() < minX) minX = incoming.x();
          if (incoming.y() < minY) minY = incoming.y();
        }
        if (outgoing) {
          if (outgoing.x() > maxX) maxX = outgoing.x();
          if (outgoing.y() > maxY) maxY = outgoing.y();
        }
      }

      const prevStagePosition = stage.current?.position();
      const prevStageScale = stage.current?.scale();

      stage.current?.position({ x: 0, y: 0 });
      stage.current?.scale({ x: 1, y: 1 });

      const screenWithMaxY = screens.find(screen => screen.y === maxY);
      if (!screenWithMaxY) return;
      const maxYKonvaScreen = stage.current?.findOne(`.${screenWithMaxY.pathId}`);

      if (!maxYKonvaScreen) return;

      const maxYHeight = maxYKonvaScreen.getClientRect({ skipTransform: true });

      let canvasWidth = Math.abs(minX) + Math.abs(maxX) + 380 + 100;
      let canvasHeight = Math.abs(minY) + Math.abs(maxY) + maxYHeight.height + 200;
      let pixelRatio = 1;
      if (canvasHeight * canvasWidth > MAX_PNG_SIZE) {
        pixelRatio = MAX_PNG_SIZE / (canvasHeight * canvasWidth);
      }

      const imageSize = `${(canvasWidth * canvasHeight) / 1e6} Mp`;
      await new Promise(resolve => {
        amplitudeInstance.logEvent(
          'J-Graph Image export start.',
          {
            imageSize: imageSize,
            projectShortName,
          },
          resolve
        );
      });
      const timeStart = Date.now();
      const dataURL = stage.current.toDataURL({
        quality: 1,
        pixelRatio: pixelRatio,
        x: minX - 100,
        y: minY - 100,
        width: canvasWidth,
        height: canvasHeight,
        mimeType: 'image/png',
      });
      amplitudeInstance.logEvent('J-Graph Image export finish.', {
        imageSize: imageSize,
        projectShortName,
        generationTime: (Date.now() - timeStart) / 1000,
      });
      const link = document.createElement('a');
      const { projectName } = JGraphProviderSubject$.getValue();
      link.download = `${projectName}${
        observableProps.selectedGroupPath ? '-' + observableProps.selectedGroupPath : ''
      }`;
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      stage.current?.position(prevStagePosition);
      stage.current?.scale(prevStageScale);
    }, [observableProps.selectedGroupPath, projectShortName, screens, stickers]);

    const onStageDragStart = useCallback(() => {
      LabelingToolMenu$.next(null);
      CollapsedConnectorsMenuClose();
    }, []);

    useEffect(() => {
      const sub = combineLatest([hiddenConnectionsPipe$, StateCollapseSubjectPipe$]).subscribe(
        ([valueConnectors, collapsedState]) => {
          if (valueConnectors.lastAction === 'load' && collapsedState.lastAction === 'load') return;
          mainSave$.next({
            path: '',
            type: 'updateJGraphVisuals',
            action: () =>
              dispatch(
                updateJGraphVisuals({
                  jGraphVisualsData: {
                    connectors: {
                      from: Array.from(valueConnectors.from),
                      to: Array.from(valueConnectors.to),
                    },
                    collapsedStates: collapsedState.value
                      .filter(stateStatus => stateStatus.collapsed)
                      .map(stateStatus => stateStatus.statePath),
                  },
                })
              ),
          });
        }
      );

      return () => sub.unsubscribe();
    }, [dispatch]);

    const stickerActions = useStickerActions();
    const stickerFeatureState = useStickerCreationMode(stage.current);

    useEffect(() => {
      const tryToggleStickerMode = () => callIfAbleToApplyHotKey(stickerFeatureState.toggleStickerMode);
      keyboardjs.on(['t'], tryToggleStickerMode);
      return () => {
        keyboardjs.off(['t'], tryToggleStickerMode);
      };
    }, [stickerFeatureState]);

    return (
      <div
        style={{ flex: 1 }}
        className={cn({
          'sticker-mode-enabled': stickerFeatureState.stickerModeOpened,
        })}
      >
        {isEditModeEnable && (
          <div className='jgraph-toolbar-container'>
            <div className='vertical-container'>
              <div className='horizontal-container'>
                <JGToolbar type='horizontal'>
                  <JGToolbarIconButton
                    className={JGToolbarClasses.jgToolbarButton}
                    iconName='farSearch'
                    onClick={() => SearchState.State$.next(true)}
                    data-test-id='JGToolbar:Search'
                    id={`${StageIDInner}_Search`}
                    tooltip={t('JGToolbar:Search')}
                    placement='bottom'
                  />
                  <ModalCreatePng
                    className={JGToolbarClasses.jgToolbarButton}
                    createPng={createPng}
                    StageIDInner={StageIDInner}
                  />
                  <CommitButtonWithContext className={JGToolbarClasses.jgToolbarButton} />
                </JGToolbar>
                <JGToolbar type='horizontal'>
                  <HighLightConnectorsStatus StageID={StageIDInner} />
                </JGToolbar>
                <JGToolbar type='horizontal'>
                  <LevelUpButton />
                </JGToolbar>
              </div>
              <JGToolbar type='vertical'>
                <JGToolbarIconButton
                  className={cn(JGToolbarClasses.jgToolbarButton, {
                    active: screenCreationMenu.open && screenCreationMenu.isOpenedByButton,
                  })}
                  iconName='farPlusSquare'
                  onClick={alternateOpenCreationMenu}
                  data-test-id='JGToolbar:ShowMenuButton'
                  id={`${StageIDInner}_ShowMenuButton`}
                  tooltip={t('JGToolbar:ShowMenuButton')}
                  placement='right'
                />
                <JGToolbarIconButton
                  className={cn(JGToolbarClasses.jgToolbarButton, {
                    active: stickerFeatureState.stickerModeOpened,
                  })}
                  iconName='farStickyNote'
                  onClick={stickerFeatureState.toggleStickerMode}
                  data-test-id='JGToolbar:CreateSticker'
                  id={`${StageIDInner}_CreateSticker`}
                  tooltip={t('JGToolbar:StickyNote')}
                  placement='right'
                />
              </JGToolbar>
              <JGToolbar type='vertical'>
                <AutoPlacementButton stageInnerId={StageIDInner} classname={JGToolbarClasses.jgToolbarButton} />
              </JGToolbar>
            </div>

            <FloatingConnectorMenu />
            <LabelingToolMenu />
            <CollapsedConnectorsMenu />
          </div>
        )}
        <Stage
          id={StageIDInner}
          width={size.width}
          height={size.height}
          isStage={true}
          style={{ cursor: '' }}
          ref={stageGetRef}
          onDragStart={onStageDragStart}
          draggable={!isAddingMenuOpen}
          onDragEnd={setScaleAndSave}
          onWheel={setScaleAndSave}
          actions={stageActions}
          onClick={onStageClick}
          onMouseUp={newConnectorEndRx}
          onContextMenu={openContextMenuHandler}
          useStrictMode
          ctx={observableProps}
          parentScreenPath={parentScreenPath}
        >
          {stageDidMount ? (
            <>
              <ConnectorLayer ref={splinesLayer} isEditModeEnable={isEditModeEnable} />
              <Layer ref={blocksLayer} isLayer={true}>
                {showIncomingOutgoingConnections && (
                  <IncomingOutgoingConnections
                    type='incoming'
                    connections={showIncomingConnectors}
                    position={initialIncomingOutgoingPosition.incoming}
                    connectorsFromStore$={observableProps.connectorsFromStore$}
                    listening={isEditModeEnable}
                  />
                )}
                {screens.map(screen => (
                  <StateScreen
                    key={screen.path}
                    screen={screen}
                    draggable={isEditModeEnable && !isAddingMenuOpen}
                    isEditModeEnable={isEditModeEnable}
                    saveBlockMovement={saveMove}
                    setEditMenuBlock={stageActions.setEditMenuBlock}
                    recalculateConnections={observableProps.recalculateConnections}
                    fromStateTransitions={fromStateTransitions}
                  />
                ))}

                {showIncomingOutgoingConnections && (
                  <IncomingOutgoingConnections
                    type='outgoing'
                    connections={showOutgoingConnectors}
                    position={initialIncomingOutgoingPosition.outgoing}
                    connectorsFromStore$={observableProps.connectorsFromStore$}
                    listening={isEditModeEnable}
                  />
                )}
              </Layer>
              <ActionLayer ref={actionLayer} />
              {observableProps.isActive && <StickerLayer stickerActions={stickerActions} stickers={stickers} />}
            </>
          ) : null}
        </Stage>
      </div>
    );
  }
);
StageView.displayName = 'StageView';
