import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import localize, { t } from '../../localization';
import * as ProjectsActions from '../../actions/projects.actions';
import { Icon, DropdownButton, DropdownToggle, DropdownMenu, SearchInput, DropdownItem } from '@just-ai/just-ui';
import { projectGroupLocalization } from '../../modules/ProjectGroups/localization/projectGroup.loc';
import { ProjectGroupsContext } from '../../modules/ProjectGroups/context';
import { Link } from 'react-router-dom';
import { isInternal } from '../../isAccessFunction';
import classes from './styles.module.scss';
import classNames from 'classnames';

localize.addTranslations(projectGroupLocalization);

type Project = {
  name: string;
  shortName: string;
};

type DispatchProps = {
  actions: {
    setCurrentProject: (
      newProjectShortName: string,
      fromTemplate: boolean,
      isNewProject: boolean,
      shouldRedirect: boolean
    ) => void;
  };
};

type Props = {
  projectList: Project[];
  currentProjectShortName: string;
  currentAccountName: string;
};

class ProjectSelector extends Component<Props & DispatchProps> {
  static contextType = ProjectGroupsContext;

  state = {
    filter: '',
  };

  selectProject = (name: string) => this.props.actions.setCurrentProject(name, false, false, true);
  setFilter = (filter = '') => this.setState({ filter });

  render() {
    const { projectList = [], currentAccountName } = this.props;
    const { projectShortName: currentProjectShortName, currentProjectGroupObject } = this.context;

    const projectSelectOptions = projectList.map(pr => ({ label: pr.name, value: pr.shortName }));
    const index = projectList.findIndex(project => project.shortName === currentProjectShortName);
    const selectedProjectOption = projectSelectOptions[index];
    const filteredProjects = projectSelectOptions.filter(pr =>
      `${pr.value} ${pr.label}`.toLowerCase().includes(this.state.filter.toLowerCase())
    );

    return (
      <div className='project__selector'>
        <Link
          to='/'
          data-test-id='ProjectSelector.link'
          style={window.location.pathname === '/' ? { cursor: 'default' } : undefined}
        >
          <Icon name='farFolder' /> {isInternal() ? currentAccountName : currentProjectGroupObject?.name}
        </Link>
        <span className='project__selector-separator'>/</span>

        <DropdownButton
          title={selectedProjectOption?.label || ''}
          disabled={!projectList.length}
          className={classes.DropdownButton}
          direction='down'
        >
          <DropdownToggle color='none' iconButton data-test-id='Project.Selector'>
            {selectedProjectOption?.label ? (
              <span>{selectedProjectOption?.label}</span>
            ) : (
              <span
                className={classNames(!projectList.length && classes.NoProject)}
                data-test-id='Project.Selector.Menu.NoSelectedProject'
              >
                {t('Select project')}
              </span>
            )}
            <Icon className='btn-icon ml-2' size='sm' name='faSort' />
          </DropdownToggle>
          <DropdownMenu className={classes.DropdownMenu} data-test-id='Project.Selector.Menu'>
            <SearchInput
              placeholder={t('Search:Projects:Placeholder')}
              data-test-id='Project.Selector.Menu.Search'
              clearable={!!this.state.filter}
              value={this.state.filter}
              onChange={this.setFilter}
              className={classes.Input}
              autoFocus
              onClear={this.setFilter}
              AppendInner={[]}
              alwaysOpen
            />

            <div className={classes.Projects} data-test-id='Project.Selector.Menu.Projects'>
              {filteredProjects.map(({ label, value }) => (
                <DropdownItem key={value} onClick={() => this.selectProject(value)}>
                  {label}
                </DropdownItem>
              ))}
            </div>

            {!filteredProjects.length && this.state.filter && (
              <span className={classes.NoFound} data-test-id='Project.Selector.Menu.Projects.NoFound'>
                {t('Search:Projects:Placeholder:NoFound')}
              </span>
            )}
          </DropdownMenu>
        </DropdownButton>
      </div>
    );
  }
}

//@ts-ignore
function mapStateToProps(state): Props {
  return {
    projectList: state.ProjectsReducer.projectList,
    currentProjectShortName: state.CurrentProjectsReducer.currentProject,
    currentAccountName: state.CurrentAccountReducer.account.accountName,
  };
}
//@ts-ignore
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ProjectsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelector);
