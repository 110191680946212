var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { Component, createContext, useContext } from 'react';
import { ProjectStatsService, LabelsService } from '../service/AnalyticsService';
import { getMethodDateFormat, prepareOptions } from '../utils/ContextUtils';
import AnalyticsChartData, { StatsData } from '../service/AnalyticsChartData';
import { axios } from '../utils/pipes/functions';
import filterSessionIdController from '../service/filterSessionIdController';
import { AnalyticsActiveFilters } from '../service/AnalyticsActiveFilters';
import { LabelsContext } from '../../Labels';
import { Spinner } from '@just-ai/just-ui';
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
export var AnalyticsContext = createContext({});
var State = /** @class */ (function () {
    function State() {
        this.isLoading = false;
        this.methodsNotAllowed = true;
        this.analyticsContextIsReady = false;
        this.clientsFilterQuery = {};
        this.sessions = [];
        this.routes = [];
        this.session = null;
        this.messages = [];
        this.sessionMessages = [];
        this.analyticsChartData = {
            sessionData: new StatsData(),
            messagesData: new StatsData(),
        };
    }
    return State;
}());
var AnalyticsProvider = /** @class */ (function (_super) {
    __extends(AnalyticsProvider, _super);
    function AnalyticsProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.analyticsStatsService = new ProjectStatsService(NaN, '');
        _this.labelsService = new LabelsService(NaN, '');
        _this.analyticsChartData = new AnalyticsChartData();
        _this.filtersData = new AnalyticsActiveFilters();
        _this.state = new State();
        _this.messagesByDay = [];
        _this.axios = axios;
        _this.setFilterData = function (filterData) {
            _this.filtersData.set(filterData);
        };
        _this.getFilterData = function () {
            return _this.filtersData.get();
        };
        _this.load = function (promise) {
            _this.setState({ isLoading: true });
            return promise.finally(function () { return _this.setState({ isLoading: false }); });
        };
        _this.getSessionWithNewFilters = function (filters) {
            return _this.load(_this.analyticsStatsService.getSessionWithNewFilters(filters));
        };
        _this.getSessionLabelsWithNewFilters = function (filters) {
            return _this.load(_this.analyticsStatsService.getSessionLabelWithNewFilters(filters));
        };
        _this.getLogLabelsWithNewFilters = function (filters) {
            return _this.load(_this.analyticsStatsService.getLogLabelWithNewFilters(filters));
        };
        _this.getMessageWithNewFilters = function (filters) {
            return _this.load(_this.analyticsStatsService.getMessageWithNewFilters(filters));
        };
        _this.getFilterSetId = function (filters) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.analyticsStatsService.getFilterSetId(filters)];
            });
        }); };
        _this.getLogsByFilter2 = function (page, size, filtersDto) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.load(this.analyticsStatsService.getLogsByFilter2(page, size, filtersDto))];
                    case 1:
                        data = _a.sent();
                        this.setState({ messages: data.messages }, this.loadIssues.bind(this, 'sessionMessages'));
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.loadIssues = function (type, questionId) {
            if (type === void 0) { type = 'messages'; }
            return __awaiter(_this, void 0, void 0, function () {
                var messages, projectShortName, uniqQuestionIdsInSession_1, issues_1, issuesPromises, messagesWithIssues;
                var _a;
                var _this = this;
                var _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            messages = this.state[type];
                            projectShortName = this.props.projectShortName;
                            if (!((_b = this.props.config.messages) === null || _b === void 0 ? void 0 : _b.downloadIssue)) return [3 /*break*/, 2];
                            uniqQuestionIdsInSession_1 = messages.reduce(function (result, x) {
                                if (questionId && x.questionId !== questionId)
                                    return result;
                                if (!result[x.sessionId]) {
                                    result[x.sessionId] = new Set();
                                }
                                result[x.sessionId].add(x.questionId);
                                return result;
                            }, {});
                            issues_1 = {};
                            issuesPromises = Object.keys(uniqQuestionIdsInSession_1).map(function (sessionId) { return __awaiter(_this, void 0, void 0, function () {
                                var messages, issue;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            messages = this.props.config.messages;
                                            if (!(messages === null || messages === void 0 ? void 0 : messages.downloadIssue)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, messages.downloadIssue({
                                                    projectShortName: projectShortName,
                                                    sessionId: sessionId,
                                                    uniqQuestionIdsInSession: uniqQuestionIdsInSession_1,
                                                })];
                                        case 1:
                                            issue = _a.sent();
                                            if (Object.keys(issue.data).length > 0) {
                                                issues_1 = __assign(__assign({}, issues_1), issue.data);
                                            }
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); });
                            return [4 /*yield*/, Promise.all(issuesPromises)];
                        case 1:
                            _c.sent();
                            if (Object.keys(issues_1).length > 0) {
                                messagesWithIssues = messages.map(function (x) {
                                    if (issues_1[x.questionId])
                                        x.issues = issues_1[x.questionId];
                                    return x;
                                });
                                this.setState((_a = {}, _a[type] = messagesWithIssues, _a));
                            }
                            _c.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        };
        _this.loadSessionIssue = function () { return __awaiter(_this, void 0, void 0, function () {
            var projectShortName, session, sessionId, data;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectShortName = this.props.projectShortName;
                        session = this.state.session;
                        sessionId = (this.state.session || {}).sessionId;
                        if (!(((_a = this.props.config.session) === null || _a === void 0 ? void 0 : _a.downloadIssue) && sessionId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.config.session.downloadIssue({
                                projectShortName: projectShortName,
                                sessionId: encodeURIComponent(sessionId),
                            })];
                    case 1:
                        data = (_b.sent()).data;
                        if ((session === null || session === void 0 ? void 0 : session.sessionId) === sessionId) {
                            this.setState({ session: __assign(__assign({}, session), { issue: data[0] || data }) });
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.getSessionsByFilter = function (page, size, filtersDto) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.load(this.analyticsStatsService.getSessionsByFilter(page, size, filtersDto))];
                    case 1:
                        data = _a.sent();
                        this.setState({ sessions: data.sessions });
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.createClientReport = function (filtersDto, timezone) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.load(this.analyticsStatsService.createClientReport(filtersDto, timezone))];
            });
        }); };
        _this.saveMessageComment = function (questionId, comment, isSessionMessages) { return __awaiter(_this, void 0, void 0, function () {
            var messages, newMessages;
            return __generator(this, function (_a) {
                messages = isSessionMessages ? this.state.sessionMessages : this.state.messages;
                newMessages = messages.map(function (x) {
                    if (x.questionId === questionId) {
                        return __assign(__assign({}, x), { comment: comment });
                    }
                    else {
                        return x;
                    }
                });
                //@ts-ignore
                this.setState(isSessionMessages ? { sessionMessages: newMessages } : { messages: newMessages });
                return [2 /*return*/];
            });
        }); };
        _this.saveMessageLabels = function (questionId, sessionId, labels, isSessionMessages) { return __awaiter(_this, void 0, void 0, function () {
            var labelsWithGroups, messages, newMessages;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        labelsWithGroups = labels.map(function (x) {
                            //@ts-ignore
                            if (x.groupId) {
                                //@ts-ignore
                                return __assign(__assign({}, x), { group: _this.context.groups.find(function (group) { return group.id === x.groupId; }) });
                            }
                            else {
                                return x;
                            }
                        });
                        return [4 /*yield*/, this.setLogLabels(questionId, sessionId, labels.map(function (x) { return ({ labelId: x.id }); }))];
                    case 1:
                        _a.sent();
                        messages = isSessionMessages ? this.state.sessionMessages : this.state.messages;
                        newMessages = messages.map(function (x) {
                            if (x.questionId === questionId) {
                                return __assign(__assign({}, x), { labels: __spreadArray([], labelsWithGroups, true) });
                            }
                            else {
                                return x;
                            }
                        });
                        //@ts-ignore
                        this.setState(isSessionMessages ? { sessionMessages: newMessages } : { messages: newMessages });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.saveSessionLabels = function (sessionId, labels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.setSessionLabels(sessionId, labels.map(function (x) { return ({ labelId: x.id }); }))];
                    case 1:
                        _a.sent();
                        if (this.state.session) {
                            this.setState({ session: __assign(__assign({}, this.state.session), { labels: labels }) });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.createNewLabelAndAddToMessage = function (questionId, sessionId, labelName, labelGroupId, labels, isSessionMessages) { return __awaiter(_this, void 0, void 0, function () {
            var label, newLabels;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, ((_a = this.context) === null || _a === void 0 ? void 0 : _a.createLabel(labelName, labelGroupId))];
                    case 1:
                        label = _b.sent();
                        newLabels = __spreadArray([label], labels, true);
                        if (labels) {
                            this.saveMessageLabels(questionId, sessionId, newLabels, isSessionMessages);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.createNewLabelAndAddToSession = function (sessionId, labelName, labels) { return __awaiter(_this, void 0, void 0, function () {
            var label, newLabels;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.context.createSessionLabel(labelName)];
                    case 1:
                        label = _a.sent();
                        newLabels = __spreadArray([label], labels, true);
                        if (label) {
                            this.saveSessionLabels(sessionId, newLabels);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        //done SWITCH
        _this.getSwitchedSessionCountByDay = function (dateFrom, dateTo, transferToOperatorMethod, botIds) {
            var _a = getMethodDateFormat(dateFrom, dateTo), dateFromFormatted = _a[0], dateToFormatted = _a[1];
            return _this.load(_this.analyticsStatsService.getSwitchedSessionCountByDay(dateFromFormatted, dateToFormatted, transferToOperatorMethod, botIds));
        };
        _this.saveClientsFilterQuery = function (clientsFilterQuery) {
            _this.setState({ clientsFilterQuery: clientsFilterQuery });
        };
        _this.setLogLabels = function (questionId, sessionId, labels) {
            return _this.load(_this.labelsService.setLogLabels(questionId, sessionId, labels));
        };
        _this.setSessionLabels = function (sessionId, labels) {
            return _this.load(_this.labelsService.setSessionLabels(sessionId, labels));
        };
        _this.getClientByFilter = function (page, size, filtersDto) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.load(this.analyticsStatsService.getClientByFilter(page, size, filtersDto))];
            });
        }); };
        _this.getMessagesForClient = function (botId, clientId, logCount, startDate) {
            return _this.load(_this.analyticsStatsService.getMessagesForClient(botId, decodeURIComponent(clientId), logCount, startDate));
        };
        _this.getMessagesForClientDialog = function (botId, clientId, logCount, startDate, sort, page, channelType, options) {
            return _this.analyticsStatsService.getMessagesForClient(botId, decodeURIComponent(clientId), logCount, startDate, sort, page, channelType, options);
        };
        _this.blockClient = function (botId, clientId, blockClientDto) {
            return _this.load(
            //@ts-ignore
            axios._put("/restapi/clients/block?clientId=".concat(encodeURIComponent(clientId), "&botId=").concat(encodeURIComponent(botId)), __assign(__assign({}, blockClientDto), { accountId: _this.props.accountId })));
        };
        _this.unblockClient = function (botId, clientId) {
            return _this.load(
            //@ts-ignore
            axios._put("/restapi/clients/unblock?clientId=".concat(encodeURIComponent(clientId), "&botId=").concat(encodeURIComponent(botId))));
        };
        _this.getMessagePageInSession = function (sessionId, questionId, botId) {
            return _this.load(_this.analyticsStatsService.getMessagePageInSession(sessionId, questionId, botId));
        };
        _this.getMessagesForSession = function (sessionId, page, responseDataJsonPath, botId, startTime, hideEmptyMessages) { return __awaiter(_this, void 0, void 0, function () {
            var data, e_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        (_a = this.cancelTokenMessagesForSession) === null || _a === void 0 ? void 0 : _a.cancel();
                        this.cancelTokenMessagesForSession = Axios.CancelToken.source();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.analyticsStatsService.getMessagesForSession(sessionId, page, responseDataJsonPath, botId, startTime, hideEmptyMessages, { cancelToken: this.cancelTokenMessagesForSession.token })];
                    case 2:
                        data = _b.sent();
                        this.setState({
                            isLoading: false,
                            sessionMessages: data.messages,
                            session: data.session,
                        }, this.loadIssues);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        console.log(e_1);
                        return [2 /*return*/, { logs: [], session: {}, paging: {} }];
                    case 4: return [2 /*return*/, data];
                }
            });
        }); };
        _this.createMessageReport = function (options) {
            if (options === void 0) { options = {}; }
            return _this.load(_this.analyticsStatsService.createMessageReport(options));
        };
        _this.createRouteReport = function (options) {
            if (options === void 0) { options = {}; }
            return _this.load(_this.analyticsStatsService.createRouteReport(options));
        };
        _this.setAnalyticChartData = function () {
            _this.setState({
                analyticsChartData: {
                    sessionData: _this.analyticsChartData.sessionStats,
                    messagesData: _this.analyticsChartData.messagesStats,
                },
            });
        };
        _this.getRoutesDataByFilter = function (page, size, filtersDto) { return __awaiter(_this, void 0, void 0, function () {
            var data, routesWithId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.load(this.analyticsStatsService.getRoutesDataByFilter(page, size, filtersDto))];
                    case 1:
                        data = _a.sent();
                        routesWithId = data.routes.map(function (route) { return (__assign(__assign({}, route), { generatedId: uuidv4() })); });
                        this.setState({ routes: routesWithId });
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.getMessagesAndSessionStats = function (location, filters) { return __awaiter(_this, void 0, void 0, function () {
            var _a, sessionData, messageData;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.getSessionWithNewFilters(filters),
                            this.getMessageWithNewFilters(filters),
                        ])];
                    case 1:
                        _a = _b.sent(), sessionData = _a[0], messageData = _a[1];
                        filterSessionIdController.save(location, sessionData.filterSetId, this.props.history);
                        this.analyticsChartData.setMessagesAndSessionData(sessionData.stats, messageData.stats);
                        this.setAnalyticChartData();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.toggleChartActivity = function (name) {
            _this.analyticsChartData.toggleActivity(name);
            _this.setAnalyticChartData();
        };
        _this.getSessionLabelsStats = function (location, filters) {
            return _this.load(_this.analyticsStatsService.getSessionLabelsStats(filters));
        };
        _this.getLogLabelStats = function (filter) {
            return _this.load(_this.analyticsStatsService.getLogLabelWithNewFilters(filter));
        };
        _this.getTelephonyStats = function (dateFrom, dateTo, botIds) {
            // const preparedFilter = prepareOptions(options);
            var _a = getMethodDateFormat(dateFrom, dateTo), dateFromFormatted = _a[0], dateToFormatted = _a[1];
            return _this.load(
            //@ts-ignore
            _this.analyticsStatsService.getTelephonyStats(dateFromFormatted, dateToFormatted, botIds));
        };
        _this.getTelephonyReport = function (options) {
            var filterOptions = prepareOptions(options);
            return _this.load(_this.analyticsStatsService.getTelephonyReport(filterOptions));
        };
        _this.deleteReportGenerationTask = function (options) {
            return _this.load(_this.analyticsStatsService.deleteReportGenerationTask(options));
        };
        _this.deleteCompletedReportGenerationTasks = function (options) {
            return _this.load(_this.analyticsStatsService.deleteCompletedReportGenerationTasks(options));
        };
        _this.createSessionReport = function (options) {
            if (options === void 0) { options = {}; }
            return _this.load(_this.analyticsStatsService.createSessionReport(options));
        };
        _this.createAnalyticsToken = function (projectShortName) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.analyticsStatsService.createAnalyticsToken(projectShortName)];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data.token];
                }
            });
        }); };
        _this.getAnalyticsTokenByProjectShortName = function (projectShortName) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.analyticsStatsService.getAnalyticsTokenByProjectShortName(projectShortName)];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data.token];
                }
            });
        }); };
        _this.deleteAnalyticsTokenByProjectShortName = function (projectShortName) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.analyticsStatsService.deleteAnalyticsTokenByProjectShortName(projectShortName)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        return _this;
    }
    AnalyticsProvider.prototype.componentDidMount = function () {
        this.axios = this.props.axios || axios;
        this.timeZone = this.props.timeZone;
        this.analyticsStatsService = new ProjectStatsService(this.props.accountId, this.props.projectShortName, this.axios);
        this.labelsService = new LabelsService(this.props.accountId, this.props.projectShortName, this.axios);
        if (this.props.accountId > 0 && Boolean(this.props.projectShortName)) {
            this.setState({
                methodsNotAllowed: false,
                analyticsContextIsReady: true,
            });
        }
        if (!this.context.loaded && this.context.getLabels) {
            this.context.getLabels();
        }
    };
    AnalyticsProvider.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        this.analyticsStatsService = new ProjectStatsService(this.props.accountId, this.props.projectShortName, this.axios);
        this.labelsService = new LabelsService(this.props.accountId, this.props.projectShortName, this.axios);
        var methodsNotAllowed = false;
        if (prevProps.projectShortName &&
            this.props.projectShortName &&
            prevProps.projectShortName !== this.props.projectShortName) {
            methodsNotAllowed = true;
        }
        if (!methodsNotAllowed &&
            this.props.accountId > 0 &&
            Boolean(this.props.projectShortName) &&
            this.state.methodsNotAllowed) {
            methodsNotAllowed = false;
        }
        if (this.state.methodsNotAllowed !== methodsNotAllowed) {
            this.setState({
                methodsNotAllowed: methodsNotAllowed,
                analyticsContextIsReady: !methodsNotAllowed,
            });
        }
    };
    AnalyticsProvider.prototype.render = function () {
        var _this = this;
        var clientsFilterQuery = this.state.clientsFilterQuery;
        return (React.createElement(AnalyticsContext.Provider, { value: {
                axios: this.axios,
                accountId: this.props.accountId,
                analyticsChartData: this.state.analyticsChartData,
                analyticsContextIsReady: this.state.analyticsContextIsReady,
                clientsFilterQuery: clientsFilterQuery,
                config: this.props.config,
                isLoading: this.state.isLoading,
                projectShortName: this.props.projectShortName,
                locale: this.props.locale,
                methodsNotAllowed: this.state.methodsNotAllowed,
                messages: this.state.messages,
                sessions: this.state.sessions,
                session: this.state.session,
                sessionMessages: this.state.sessionMessages,
                routes: this.state.routes,
                blockClient: this.blockClient,
                createAnalyticsToken: this.createAnalyticsToken,
                createClientReport: this.createClientReport,
                createSessionReport: this.createSessionReport,
                createMessageReport: this.createMessageReport,
                createRouteReport: this.createRouteReport,
                createNewLabelAndAddToMessage: this.createNewLabelAndAddToMessage,
                createNewLabelAndAddToSession: this.createNewLabelAndAddToSession,
                getSessionWithNewFilters: this.getSessionWithNewFilters,
                deleteReportGenerationTask: this.deleteReportGenerationTask,
                deleteCompletedReportGenerationTasks: this.deleteCompletedReportGenerationTasks,
                deleteAnalyticsTokenByProjectShortName: this.deleteAnalyticsTokenByProjectShortName,
                getAnalyticsTokenByProjectShortName: this.getAnalyticsTokenByProjectShortName,
                getClientByFilter: this.getClientByFilter,
                getMessagesForClient: this.getMessagesForClient,
                getMessagesForClientDialog: this.getMessagesForClientDialog,
                getFilterData: this.getFilterData,
                getFilterSetId: this.getFilterSetId,
                getLogLabelsWithNewFilters: this.getLogLabelsWithNewFilters,
                getLogLabelStats: this.getLogLabelStats,
                getLogsByFilter2: this.getLogsByFilter2,
                getMessagesForSession: this.getMessagesForSession,
                getMessagesAndSessionStats: this.getMessagesAndSessionStats,
                getMessagePageInSession: this.getMessagePageInSession,
                getSwitchedSessionCountByDay: this.getSwitchedSessionCountByDay,
                getSessionLabelsStats: this.getSessionLabelsStats,
                getSessionsByFilter: this.getSessionsByFilter,
                getRoutesByFilter: this.getRoutesDataByFilter,
                getTelephonyStats: this.getTelephonyStats,
                getTelephonyReport: this.getTelephonyReport,
                t: this.props.t,
                prepareMessagesAndSessionsGraphData: function () {
                    return _this.analyticsChartData.prepareMessagesAndSessionsGraphData(_this.props.t);
                },
                saveClientsFilterQuery: this.saveClientsFilterQuery,
                saveMessageComment: this.saveMessageComment,
                saveMessageLabels: this.saveMessageLabels,
                saveSessionLabels: this.saveSessionLabels,
                loadIssues: this.loadIssues,
                loadSessionIssue: this.loadSessionIssue,
                setFilterData: this.setFilterData,
                setLogLabels: this.setLogLabels,
                toggleChartActivity: this.toggleChartActivity,
                unblockClient: this.unblockClient,
                labelsContext: this.context,
                history: this.props.history,
                timeZone: this.timeZone,
                isAccess: this.props.isAccess || (function () { return false; }),
            } },
            this.props.children,
            this.state.isLoading && React.createElement(Spinner, { size: '4x', backgroundColor: 'rgba(255,255,255, 0.3)' })));
    };
    AnalyticsProvider.contextType = LabelsContext;
    return AnalyticsProvider;
}(Component));
export { AnalyticsProvider };
export var useAnalyticsContext = function () { return useContext(AnalyticsContext); };
